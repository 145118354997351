import React, { useMemo } from 'react'
import { Card, CardContent } from '@/components/ui/card'
import { WebappEstateFragment } from '@gql'
import { useTranslation } from 'react-i18next'
import {
  canEstateBeEstimated,
  EstateEstimateTodoList,
} from './EstateEstimateTodoList'
import { EstateSendMailEstimateButton } from './EstateSendMailEstimateButton'
import { useHasuraClaim } from '@nhost/react'
import { formatDate } from '@utils/dates'
import { EstateEstimateForm } from './EstateEstimateForm'
import { InfoIcon } from 'lucide-react'

type EstateEstimatePriceCardProps = {
  estate: WebappEstateFragment
}
export const EstateEstimatePriceCard = ({
  estate,
}: EstateEstimatePriceCardProps) => {
  const { t } = useTranslation()

  const {
    startEstimate,
    endEstimate,
    estimationAskedBy,
    estimationAskedDate,
    estimationPublishedDate,
  } = estate

  const canBeEstimated = canEstateBeEstimated(estate)
  const sellerMemberId = useHasuraClaim('seller-member-id')
  const isSellerMember = !!sellerMemberId && sellerMemberId !== 'null'
  const agencyMemberId = useHasuraClaim('agency-member-id')
  const isAgencyMember = !!agencyMemberId && agencyMemberId !== 'null'
  const isPublished = !!estimationPublishedDate
  const isEstimated = !!startEstimate && !!endEstimate

  const estimationAskedByLabel = useMemo(() => {
    if (estimationAskedBy?.id === sellerMemberId) return t('common.yourself')
    return [estimationAskedBy?.firstName, estimationAskedBy?.lastName]
      .filter(Boolean)
      .join(' ')
  }, [estimationAskedBy, sellerMemberId])

  const estimationAskedFormattedDate = useMemo(() => {
    return formatDate(estimationAskedDate)
  }, [estimationAskedDate])

  return (
    <div className="tw-space-y-4">
      <p className="tw-text-2xl tw-font-semibold">
        {t('EstateEstimatePriceCard.heading')}
      </p>
      <Card>
        <CardContent className="tw-grid tw-gap-4 tw-pt-4">
          {!(isSellerMember && isEstimated && isPublished) && (
            <EstateEstimateTodoList estate={estate} />
          )}
          {!isEstimated && estimationAskedDate && estimationAskedBy && (
            <div className="tw-bg-green-100 tw-text-green-700 tw-rounded-lg tw-px-6 tw-py-4 tw-flex tw-items-center tw-gap-4">
              <div>
                <InfoIcon className="tw-flex-shrink-0 tw-h-[24px] tw-w-[24px]" />
              </div>
              <div className="tw-text-sm">
                {t('EstateEstimatePriceCard.estimationAsked', {
                  estimationAskedByLabel,
                  estimationAskedFormattedDate,
                })}
              </div>
            </div>
          )}
          {isSellerMember && isEstimated && isPublished && (
            <p className="tw-text-sm">
              {t('EstateEstimatePriceCard.estimation', {
                startEstimate: t('common.currency.simple', {
                  val: startEstimate,
                }),
                endEstimate: t('common.currency.simple', {
                  val: endEstimate,
                }),
              })}
            </p>
          )}
          {isSellerMember && !isEstimated && !estimationAskedDate && (
            <EstateSendMailEstimateButton
              estate={estate}
              disabled={!canBeEstimated}
            />
          )}
          {isAgencyMember && canBeEstimated && (
            <EstateEstimateForm estate={estate} />
          )}
        </CardContent>
      </Card>
    </div>
  )
}
