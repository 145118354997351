import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { useTranslation } from 'react-i18next'
import {
  WebappEstateFragment,
  SellerFragment,
  useUpdateEstateSellerMutation,
  useCreateEstateSellersMutation,
  EstateSellerFragment,
  useDeleteEstateSellersMutation,
  useEstateSellersSubscription,
  Estate_Seller_Insert_Input,
} from '@gql'
import { FieldErrors, useFieldArray, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import useSellers from '@/seller/hooks/useSellers'
import { Loading } from '@/common/atoms/Loading'
import { InputNumber } from '@/components/ui/inputNumber'
import { Switch } from '@/components/ui/switch'
import { Button } from '@/components/ui/button'
import { Label } from '@/components/ui/label'
import { X } from 'lucide-react'
import { SellerMemberCombobox } from '@/sellerMember/components/SellerMemberCombobox'
import {
  EstateSellerDataFormValues,
  useEstateSellerDataSchema,
} from '@/estate/hooks/useEstateSellerDataSchema'
import { SellerSelect } from '@/seller/components/SellerSelect'
import SellerCreateModal from '@/seller/modals/SellerCreateModal'
import { uniqueFilter } from '@utils/array'

export type EstateSellerDataFormProps = {
  estate: WebappEstateFragment
  onSubmit: (values: Partial<WebappEstateFragment>) => void
  readOnly?: boolean
}
export const EstateSellerDataForm = ({
  estate,
  onSubmit,
  readOnly,
}: EstateSellerDataFormProps) => {
  const { t } = useTranslation()
  const [key, setKey] = useState(0) // key to force re-render with empty value

  const { schema } = useEstateSellerDataSchema()

  const {
    sellers: allSellers,
    loading: sellersLoading,
    error: sellersError,
  } = useSellers()
  const sellers = useMemo(() => {
    return (
      allSellers?.filter((s) => !s.archived && s.id !== estate.sellerId) || []
    )
  }, [allSellers])

  const getSellerLabel = (sellerId: string) => {
    const seller = sellers?.find((seller) => seller.id === sellerId)
    return seller?.name || ''
  }

  const {
    data: estateSellersData,
    loading: estateSellersLoading,
    error: estateSellersError,
  } = useEstateSellersSubscription({
    variables: {
      estateId: estate.id,
    },
  })
  const estateSellers = estateSellersData?.estate_seller ?? []
  const estateSellerStructures = estateSellers.map((estateSeller) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { __typename, ...sellerStructure } = estateSeller
    return sellerStructure
  })

  const defaultValues = {
    sellerStructures: [],
    singleRepresentativeForMultipleSellers: false,
    mandatedSellerWithPowerDelegation: '',
  }

  const form = useForm<EstateSellerDataFormValues>({
    resolver: yupResolver(schema),
    defaultValues,
  })

  const [estateSellersLoadedFlag, setEstateSellersLoadedFlag] = useState(false)
  const riseEstateSellersLoadedFlag = () => {
    setEstateSellersLoadedFlag(true)
  }

  useEffect(() => {
    if (estateSellersData?.estate_seller) riseEstateSellersLoadedFlag()
  }, [estateSellersData?.estate_seller])

  useEffect(() => {
    if (!estateSellersLoadedFlag) return
    form.reset({
      sellerStructures: estateSellerStructures,
      singleRepresentativeForMultipleSellers:
        estate.sellerData?.singleRepresentativeForMultipleSellers || false,
      mandatedSellerWithPowerDelegation:
        estate.sellerData?.mandatedSellerWithPowerDelegation || '',
    })
  }, [estateSellersLoadedFlag])

  const {
    fields: sellerStructures,
    append: appendSellerStructure,
    remove: removeSellerStructure,
  } = useFieldArray({
    control: form.control,
    name: 'sellerStructures',
  })

  const onInvalid = (errors: FieldErrors<EstateSellerDataFormValues>) => {
    console.log({ errors })
  }

  const [
    createEstateSellersMutation,
    { loading: createLoading, error: createError },
  ] = useCreateEstateSellersMutation()
  const createMultiple = (valuesToCreate: Estate_Seller_Insert_Input[]) => {
    createEstateSellersMutation({
      variables: {
        valuesToCreate,
      },
    })
  }
  const [
    updateEstateSellerMutation,
    { loading: updateLoading, error: updateError },
  ] = useUpdateEstateSellerMutation()
  const updateOne = (variables: EstateSellerFragment) => {
    updateEstateSellerMutation({ variables })
  }

  const [
    deleteEstateSellersMutation,
    { loading: deleteLoading, error: deleteError },
  ] = useDeleteEstateSellersMutation()
  const deleteMultiple = (sellerIdsToDelete: string[], estateId: string) => {
    deleteEstateSellersMutation({
      variables: {
        sellerIdsToDelete,
        estateId,
      },
    })
  }

  const getChanges = useCallback(
    (
      nextSellerStructures: EstateSellerDataFormValues['sellerStructures'] = []
    ) => {
      if (!estateSellersLoadedFlag)
        return { toCreate: [], toUpdate: [], toDelete: [] }
      const toCreate = nextSellerStructures
        .filter(
          (nextSellerStructure) =>
            !estateSellerStructures.find(
              (sellerStructure) =>
                sellerStructure.sellerId === nextSellerStructure.sellerId
            ) && nextSellerStructure.sellerId !== estate.sellerId
        )
        .map((item) => ({ ...item, estateId: estate.id }))

      const toUpdate = nextSellerStructures
        .filter(
          (nextSellerStructure) =>
            estateSellerStructures.find(
              (sellerStructure) =>
                sellerStructure.sellerId === nextSellerStructure.sellerId &&
                sellerStructure.ownershipShare !==
                  nextSellerStructure.ownershipShare
            ) && nextSellerStructure.sellerId !== estate.sellerId
        )
        .map((item) => ({ ...item, estateId: estate.id }))

      const toDelete = estateSellerStructures.filter(
        (sellerStructure) =>
          !nextSellerStructures.find(
            (nextSellerStructure) =>
              nextSellerStructure.sellerId === sellerStructure.sellerId
          ) && sellerStructure.sellerId !== estate.sellerId
      )
      return { toCreate, toUpdate, toDelete }
    },
    [estateSellersLoadedFlag, estateSellerStructures]
  )

  const handleSubmit = () => {
    if (readOnly) return
    form.handleSubmit((values) => {
      const sellerData = {
        singleRepresentativeForMultipleSellers:
          values.singleRepresentativeForMultipleSellers,
        mandatedSellerWithPowerDelegation:
          values.mandatedSellerWithPowerDelegation,
      }

      onSubmit({ sellerData })

      const { toCreate, toUpdate, toDelete } = getChanges(
        values.sellerStructures
      )

      if (toCreate.length) createMultiple(toCreate)

      if (toUpdate.length)
        toUpdate.forEach((item) => {
          updateOne(item)
        })

      if (toDelete.length)
        deleteMultiple(
          toDelete.map((sellerStructure) => sellerStructure.sellerId),
          estate.id
        )
    }, onInvalid)()
  }

  useEffect(() => {
    const { unsubscribe } = form.watch((value, { name, type }) => {
      if (type !== 'change') return
      if (
        name === 'singleRepresentativeForMultipleSellers' &&
        value.singleRepresentativeForMultipleSellers === true
      )
        return
      if (
        name !== 'singleRepresentativeForMultipleSellers' &&
        name !== 'mandatedSellerWithPowerDelegation'
      )
        return

      handleSubmit()
    })
    return () => unsubscribe()
  }, [form.watch])

  const formValues = form.watch()

  const handleSellerSelect = (sellerId: string) => {
    const seller = sellers?.find((s) => s.id === sellerId)
    if (!seller) return
    if (sellerStructures.find((ss) => ss.sellerId === sellerId)) return
    appendSellerStructure({
      sellerId,
      ownershipShare: estateSellerOwnershipShare / 2,
    })
    handleSubmit()
    setKey(key + 1) // key to force re-render with empty value
  }

  const handleSellerDelete = (index: number) => {
    removeSellerStructure(index)
    handleSubmit()
  }

  const estateSellerOwnershipShare = useMemo(() => {
    if (!formValues.sellerStructures) return 100
    return (
      100 -
        formValues.sellerStructures.reduce(
          (acc, s) => acc + parseFloat(`${s.ownershipShare}`),
          0
        ) || 0
    )
  }, [formValues])

  const involvedSellerIds = useMemo(() => {
    return [
      estate.sellerId,
      ...((sellerStructures?.map((ss) => ss.sellerId) ||
        []) as SellerFragment['id'][]),
    ].filter(uniqueFilter)
  }, [sellerStructures])

  const [selectedSeller, setSelectedSeller] = useState<SellerFragment | null>(
    null
  )

  const loading =
    estateSellersLoading ||
    sellersLoading ||
    createLoading ||
    updateLoading ||
    deleteLoading

  const error =
    estateSellersError ||
    sellersError ||
    createError ||
    updateError ||
    deleteError

  useEffect(() => {
    if (error) console.log(`Error: ${error}`)
  }, [error])

  return (
    <>
      {loading && <Loading center active />}
      {error && <span>{t('EstateSellerDataForm.error')}</span>}
      <Form {...form}>
        <form className="tw-flex tw-flex-col tw-gap-4">
          <div className="tw-flex tw-flex-row tw-gap-4 tw-align-center tw-justify-between tw-items-center">
            <Label>{estate.seller.name}</Label>
            <Label className="tw-text-muted-foreground tw-flex tw-flex-col tw-items-start tw-gap-3">
              {t('EstateSellerDataForm.ownershipShare')}
              <InputNumber value={estateSellerOwnershipShare} disabled />
            </Label>
          </div>
          {sellerStructures.map(({ id, sellerId }, index: number) => (
            <div
              key={id}
              className="tw-flex tw-flex-row tw-gap-4 tw-align-center tw-justify-between tw-items-center"
            >
              {!readOnly && (
                <Button type="button" onClick={() => handleSellerDelete(index)}>
                  <X className="tw-size-3 tw-stroke-current" />
                </Button>
              )}
              <Label>{getSellerLabel(sellerId)}</Label>
              <FormField
                control={form.control}
                name={`sellerStructures.${index}.ownershipShare`}
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormLabel>
                        {t('EstateSellerDataForm.ownershipShare')}
                      </FormLabel>
                      <FormControl>
                        <InputNumber
                          {...field}
                          min={0}
                          max={100}
                          disabled={readOnly}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )
                }}
              />
            </div>
          ))}

          {!readOnly && (
            <>
              <SellerSelect
                field={{
                  onChange: handleSellerSelect,
                  onBlur: () => {},
                  value: selectedSeller?.id,
                  name: 'sellerStructures',
                  ref: () => {},
                }}
                filter={(seller) =>
                  !seller.archived &&
                  seller.id !== estate.sellerId &&
                  !sellerStructures.find(
                    (sellerStructure) => sellerStructure.sellerId === seller.id
                  )
                }
              />
              <SellerCreateModal
                onSuccess={(seller) => setSelectedSeller(seller)}
              />
            </>
          )}

          {!readOnly && (
            <FormField
              control={form.control}
              name={`singleRepresentativeForMultipleSellers`}
              render={({ field: { onChange, value, ...field } }) => (
                <FormItem className="tw-flex tw-flex-row tw-gap-4 tw-items-center">
                  <FormLabel>
                    {t(
                      'EstateSellerDataForm.singleRepresentativeForMultipleSellers'
                    )}
                  </FormLabel>
                  <FormControl>
                    <Switch
                      {...field}
                      checked={!!value}
                      onCheckedChange={onChange}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          )}
          {formValues.singleRepresentativeForMultipleSellers && (
            <FormField
              control={form.control}
              name={`mandatedSellerWithPowerDelegation`}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    {t(
                      'EstateSellerDataForm.mandatedSellerWithPowerDelegation'
                    )}
                  </FormLabel>
                  <FormControl>
                    <SellerMemberCombobox
                      sellerIds={involvedSellerIds}
                      {...field}
                      disabled={readOnly}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          )}
        </form>
      </Form>
    </>
  )
}
