import { nhost } from 'src/nhost'

export type UploadFileParams = {
  file: File
  id?: string
  name?: string
  bucketId?: string
}

export const uploadFile = async ({
  file,
  id,
  name,
  bucketId,
}: UploadFileParams) => {
  const { error, fileMetadata } = await nhost.storage.upload({
    file,
    id,
    name,
    bucketId,
  })
  if (error) throw error
  if (!fileMetadata.id) throw new Error('Missing file id')
  // TODO maybe replace by nhost.storage.getPresignedUrl
  const publicUrl = await nhost.storage.getPublicUrl({
    fileId: fileMetadata.id,
  })
  return { ...fileMetadata, publicUrl }
}
