import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { CheckIcon, InfoIcon, SettingsIcon } from 'lucide-react'
import { Button } from '@/components/ui/button'
import { EstateSettingLinkForm } from './EstateSettingLinkForm'
import { useUpdateEstateMutation, WebappEstateFragment } from '@gql'
import { EstateSettingOpeningForm } from './EstateSettingOpeningForm'
import { EstateSettingVisitSpanForm } from './EstateSettingVisitSpanForm'
import { EstateSettingOffersForm } from './EstateSettingOffersForm'
import debounce from 'lodash.debounce'
import { useToast } from '@/components/ui/use-toast'
import { ConfirmationDialog } from '@/components/ui/ConfirmationDialog'

type EstateSettingModalProps = {
  open: boolean
  setOpenModal: (open: boolean) => void
  estate: WebappEstateFragment
  disabled?: boolean
  readOnly?: boolean
}

export default function EstateSettingModal({
  open,
  setOpenModal,
  estate,
  disabled,
  readOnly,
}: EstateSettingModalProps) {
  const { t } = useTranslation()
  const { toast } = useToast()

  const [confirmationPendingCallback, setConfirmationPendingCallback] =
    useState<() => Promise<void> | void>()

  const [updateEstate] = useUpdateEstateMutation({
    refetchQueries: ['getEstates'],
  })
  const debounceUpdateEstate = debounce(updateEstate, 500)

  const [value, setValue] = useState('opening')
  const isPublished = estate.isPublished

  const publish = async () => {
    if (isPublished) return
    await debounceUpdateEstate({
      variables: {
        id: estate.id,
        values: {
          isPublished: true,
        },
      },
      onCompleted: () => {
        toast({
          variant: 'success',
          description: t('EstateSettingModal.toast.success'),
        })
      },
      onError: (error) => {
        console.error(error)
        toast({
          variant: 'destructive',
          description: t('EstateSettingModal.toast.error'),
        })
      },
    })
  }

  return (
    <>
      <Dialog open={open} onOpenChange={setOpenModal}>
        <DialogTrigger asChild>
          <Button disabled={disabled}>
            <SettingsIcon className="tw-h-4 tw-w-4 tw-mr-2" />
            {t('EstateSettingModal.button')}
          </Button>
        </DialogTrigger>
        <DialogContent>
          <DialogHeader className="tw-mb-5">
            <DialogTitle>{t('EstateSettingModal.heading')}</DialogTitle>
            <DialogDescription></DialogDescription>
          </DialogHeader>
          <Tabs value={value} onValueChange={setValue}>
            <TabsList className="tw-w-full">
              <TabsTrigger className="tw-w-full" value="opening">
                {t('EstateSettingModal.tabs.opening')}
              </TabsTrigger>
              <TabsTrigger className="tw-w-full" value="visits">
                {t('EstateSettingModal.tabs.visits')}
              </TabsTrigger>
              <TabsTrigger className="tw-w-full" value="offers">
                {t('EstateSettingModal.tabs.offers')}
              </TabsTrigger>
              <TabsTrigger className="tw-w-full" value="publication">
                {t('EstateSettingModal.tabs.publication')}
              </TabsTrigger>
            </TabsList>
            <TabsContent
              forceMount={true} // Required to have a smooth experience on controlled component on NextJS
              className={`tw-h-[550px] tw-overflow-auto ${value === 'opening' ? '' : 'tw-hidden'}`} // Required to have a smooth experience on controlled component on NextJS
              value="opening"
            >
              {readOnly && <EstateSettingModalReadOnlyCard estate={estate} />}
              <EstateSettingOpeningForm estate={estate} readOnly={readOnly} />
            </TabsContent>
            <TabsContent
              forceMount={true} // Required to have a smooth experience on controlled component on NextJS
              className={`tw-h-[550px] tw-overflow-auto ${value === 'visits' ? '' : 'tw-hidden'}`} // Required to have a smooth experience on controlled component on NextJS
              value="visits"
            >
              {readOnly && <EstateSettingModalReadOnlyCard estate={estate} />}
              <EstateSettingVisitSpanForm estate={estate} readOnly={readOnly} />
            </TabsContent>
            <TabsContent
              forceMount={true} // Required to have a smooth experience on controlled component on NextJS
              className={`tw-h-[550px] tw-overflow-auto ${value === 'offers' ? '' : 'tw-hidden'}`} // Required to have a smooth experience on controlled component on NextJS
              value="offers"
            >
              {readOnly && <EstateSettingModalReadOnlyCard estate={estate} />}
              <EstateSettingOffersForm estate={estate} readOnly={readOnly} />
            </TabsContent>
            <TabsContent
              forceMount={true} // Required to have a smooth experience on controlled component on NextJS
              className={`tw-h-[550px] tw-overflow-auto ${value === 'publication' ? '' : 'tw-hidden'}`} // Required to have a smooth experience on controlled component on NextJS
              value="publication"
            >
              {readOnly && <EstateSettingModalReadOnlyCard estate={estate} />}
              <EstateSettingLinkForm estate={estate} readOnly={readOnly} />
            </TabsContent>
          </Tabs>
          <DialogFooter>
            {!readOnly && (
              <Button
                variant="secondary"
                disabled={isPublished}
                onClick={() =>
                  setConfirmationPendingCallback(() => () => publish())
                }
              >
                {isPublished && <CheckIcon className="tw-w-4 tw-h-4" />}
                {isPublished
                  ? t('EstateSettingModal.published')
                  : t('EstateSettingModal.publish')}
              </Button>
            )}
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <ConfirmationDialog
        open={!!confirmationPendingCallback}
        onOpenChange={() => setConfirmationPendingCallback(undefined)}
        onConfirm={confirmationPendingCallback}
      />
    </>
  )
}

const EstateSettingModalReadOnlyCard = ({
  estate,
}: {
  estate: WebappEstateFragment
}) => {
  const { t } = useTranslation()

  return (
    <div className="tw-py-3 tw-p-4 tw-bg-green-200 tw-rounded-lg tw-flex tw-flex-row tw-gap-2 tw-text-green-700 tw-items-center">
      <InfoIcon className="tw-h-[32px] tw-w-[32px]" />
      <p className="tw-text-sm tw-font-normal tw-flex tw-items-center">
        {t('EstateSettingModal.readOnly.title', {
          agency: estate.seller.agency.name,
        })}
      </p>
    </div>
  )
}
