import React from 'react'
import { Button } from '@/components/ui/button'
import { SellerFragment } from '@gql'
import { ColumnDef } from '@tanstack/react-table'
import { ChevronsUpDownIcon } from 'lucide-react'
import { Link } from 'react-router-dom'
import { SellersTableActionDropdown } from './SellersTableActionDropdown'
import { TFunction } from 'react-i18next'
import { Badge } from '@/components/ui/badge'

export const columns = (t: TFunction): ColumnDef<SellerFragment>[] => [
  {
    accessorKey: 'name',
    filterFn: (row, _columnId, filterValue: string) => {
      return (
        row.original.name
          .toLowerCase()
          .indexOf(filterValue[0].toLowerCase()) !== -1
      )
    },
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          {t('SellersTableColumns.name')}
          <ChevronsUpDownIcon className="tw-ml-2 tw-h-4 tw-w-4" />
        </Button>
      )
    },
    cell: ({ row }) => {
      const seller = row.original
      return (
        <Link key={seller.id} to={`${seller.id}`} className="tw-font-semibold">
          {seller.name}{' '}
          {seller.archived && (
            <Badge variant="default" className="tw-ml-2">
              {t(`common.archived`)}
            </Badge>
          )}
        </Link>
      )
    },
  },
  {
    accessorKey: 'estatesStatusCounts.all',
    header: t('SellersTableColumns.nbEstates'),
  },
  {
    accessorKey: 'estatesStatusCounts.mandateSigned',
    header: t('SellersTableColumns.nbMandateSigned'),
  },
  {
    accessorKey: 'estatesStatusCounts.aoInProgress',
    header: t('SellersTableColumns.nbAoInProgress'),
  },
  {
    accessorKey: 'estatesStatusCounts.onGoing',
    header: t('SellersTableColumns.nbOnGoing'),
  },
  {
    accessorKey: 'estatesStatusCounts.aoDone',
    header: t('SellersTableColumns.nbAoDone'),
  },
  {
    accessorKey: 'estatesStatusCounts.archived',
    header: t('SellersTableColumns.nbArchived'),
  },

  {
    id: 'actions',
    cell: ({ row }) => {
      const seller = row.original
      return <SellersTableActionDropdown seller={seller} />
    },
  },
]
