import { useEmailSchema } from '@/common/hooks/schemas/useEmailSchema'
import { usePasswordSchema } from '@/common/hooks/schemas/usePasswordSchema'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

export const useAdminSigninSchema = () => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'Forms.errors',
  })

  const { schema: emailSchema } = useEmailSchema()
  const { schema: passwordSchema } = usePasswordSchema()

  const schema = useMemo(
    () =>
      yup.object().shape({
        email: emailSchema,
        ['new-password']: passwordSchema,
        ['confirm-password']: passwordSchema.oneOf(
          [yup.ref('new-password'), undefined],
          t('passwordMatch')
        ),
      }),
    [t]
  )

  return { schema }
}

export type AdminSigninFormValues = yup.InferType<
  ReturnType<typeof useAdminSigninSchema>['schema']
>
