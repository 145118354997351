import React from 'react'
import { GroupingState, Table } from '@tanstack/react-table'

import { Input } from '@/components/ui/input'
import {
  TableFacetedFilter,
  TableFilter,
} from '@/common/molecules/TableFacetedFilters'

import { Checkbox } from '@/components/ui/checkbox'
import { useTranslation } from 'react-i18next'
import { Label } from '@/components/ui/label'
import useSellers from '@/seller/hooks/useSellers'
import { TableGroupBySelect } from '../../../common/molecules/TableGroupBySelect'
import { TableEstateFragment } from './EstatesTableColumns'

interface EstatesTableToolbarProps {
  table: Table<TableEstateFragment>
  setIncludeArchived: (value: boolean) => void
  searchTerm: string
  setSearchTerm: (value: string) => void
  grouping: GroupingState
  setGrouping: (value: GroupingState) => void
}

export function EstatesTableToolbar({
  table,
  setIncludeArchived,
  searchTerm,
  setSearchTerm,
  grouping,
  setGrouping,
}: EstatesTableToolbarProps) {
  const { t } = useTranslation()
  const { sellers } = useSellers()

  const sellersOptions: TableFilter[] =
    sellers?.map((seller) => ({
      value: seller.id,
      label: seller.name,
    })) ?? []

  const groupByOptions: TableFilter[] = [
    {
      value: 'seller',
      label: t('EstatesTableToolbar.groupByField.seller'),
    },
    { value: 'status', label: t('EstatesTableToolbar.groupByField.status') },
    {
      value: 'endOfSale',
      label: t('EstatesTableToolbar.groupByField.endOfSale'),
    },
    { value: 'price', label: t('EstatesTableToolbar.groupByField.price') },
    {
      value: 'mission_head',
      label: t('EstatesTableToolbar.groupByField.mission_head'),
    },
  ]

  const setGroupByValue = (value: string) => {
    setGrouping([value])
  }

  return (
    <div className="tw-flex tw-justify-between tw-items-center tw-py-4 tw-gap-2">
      <div className="tw-flex tw-items-center tw-space-x-2 tw-w-full">
        <Input
          placeholder={t('EstatesTableToolbar.searchPlaceholder')}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="tw-max-w-sm"
        />
        {/* {table.getColumn('status') && (
          <EstatesTableFacetedFilter
            column={table.getColumn('status')}
            title="Status"
            options={estateOfferStatus}
          />
        )} */}
        {table.getColumn('seller') && (
          <TableFacetedFilter
            column={table.getColumn('seller')}
            title={t('EstatesTableToolbar.facetedFilter.sellers')}
            options={sellersOptions}
          />
        )}
        <div className="tw-flex tw-items-center tw-space-x-2 ">
          <Checkbox
            id="archived"
            onCheckedChange={(event) => setIncludeArchived(!!event)}
          />
          <Label
            htmlFor="archived"
            className="tw-text-sm tw-font-medium peer-disabled:tw-cursor-not-allowed peer-disabled:tw-opacity-70"
          >
            {t('EstatesTableToolbar.checkbox.archived')}
          </Label>
        </div>
      </div>
      <TableGroupBySelect
        groupingValue={grouping?.[0]}
        setGroupingValue={setGroupByValue}
        options={groupByOptions}
      />
    </div>
  )
}
