import React, { useMemo, useState } from 'react'
import { Button } from '@/components/ui/button'
import { useTranslation } from 'react-i18next'
import {
  Estate_Media_Type_Group_Enum,
  Estate_Media_Type_Name_Enum,
  WebappEstateFragment,
} from '@gql'
import { useEstateMediaTypes } from '@/estate/hooks/useEstateMediaTypes'
import { EstateMedia } from '../EstateMedia/EstateMedia'
import { ImageUp } from 'lucide-react'
import { useEstateMedias } from '@/estateMedia/hooks/useEstateMedias'
import { EstateMediaMultipleFileUploadDialog } from '../EstateMedia/EstateMediaMultipleFileUploadDialog'

export type EstateMediasPhotosTabProps = {
  estate: WebappEstateFragment
}

export const EstateMediasPhotosTab = ({
  estate,
}: EstateMediasPhotosTabProps) => {
  const { t } = useTranslation()

  const { estateMedias: estateMediasMedia } = useEstateMedias({
    estateId: estate.id,
    typeGroup: Estate_Media_Type_Group_Enum.Media,
  })

  const estateMedias = useMemo(() => {
    return estateMediasMedia.filter(
      (em) => em.type.name === Estate_Media_Type_Name_Enum.Photo
    )
  }, [estateMediasMedia])

  const { estateMediaTypes } = useEstateMediaTypes({
    group: Estate_Media_Type_Group_Enum.Media,
  })
  const estateMediaTypePhoto = useMemo(() => {
    if (!estateMediaTypes) return undefined
    return estateMediaTypes.find(
      (type) => type.name === Estate_Media_Type_Name_Enum.Photo
    )
  }, [estateMediaTypes])

  const [isNewMediaOpen, setIsNewMediaOpen] = useState(false)

  const handleFileUpload = () => {
    setIsNewMediaOpen(false)
  }

  return (
    <>
      <h2 className="tw-text-2xl tw-font-semibold tw-mb-5">
        {t('EstateMediasPhotosTab.title')}
      </h2>
      <div className="tw-flex tw-flex-wrap tw-gap-4 tw-w-[650px]">
        {estateMedias.map((estateMedia) => (
          <EstateMedia
            key={estateMedia.id}
            estate={estate}
            estateMedia={estateMedia}
            variant="photo"
          />
        ))}
        <Button
          variant="secondary"
          onClick={() => setIsNewMediaOpen(true)}
          className="tw-flex tw-flex-col tw-gap-4 tw-items-center tw-justify-center tw-w-[180px] tw-h-[180px] tw-bg-gray-100 tw-rounded-lg"
        >
          <ImageUp />
          {t('EstateMediasPhotosTab.add')}
        </Button>
      </div>
      <EstateMediaMultipleFileUploadDialog
        estate={estate}
        showToasts={true}
        accept="image/*"
        open={isNewMediaOpen}
        onOpenChange={() => setIsNewMediaOpen(false)}
        type={estateMediaTypePhoto!}
        onUpload={handleFileUpload}
      />
    </>
  )
}
