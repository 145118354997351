import React, { ComponentType, useEffect, useMemo } from 'react'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import {
  FieldErrors,
  FieldValues,
  Path,
  useForm,
  UseFormProps,
} from 'react-hook-form'
import { InputNumber } from '@/components/ui/inputNumber'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Switch } from '@/components/ui/switch'
import { cn } from '@/lib/utils'
import { useTranslation } from 'react-i18next'

export type GenericFormFieldType =
  | 'string'
  | 'number'
  | 'boolean'
  | 'strings'
  | 'nullableNonNegativeNumber'

export type GenericFormFieldConfig = {
  name: string
  type?: GenericFormFieldType
  required?: boolean
  label?: string
  FieldComponent?: ComponentType<any>
  containerProps?: Object & { className?: string }
  valueToInputValue?: (value: any) => any
  inputValueToValue?: (value: any) => any
  condition?: (formValues: any, context?: any) => boolean
  getComponentFieldProps?: (props: GetComponentFieldPropsProps) => Object
}

export type GenericFormConfig = GenericFormFieldConfig[]

export type GetComponentFieldPropsProps = {
  field: any
  inputValueToValue: (value: any) => any
  value: any
  onChange: (value: any) => void
  valueToInputValue: (value: any) => any
}
export const defaultGetComponentFieldProps = ({
  field,
  inputValueToValue,
  value,
  onChange,
  valueToInputValue,
}: GetComponentFieldPropsProps) => {
  return {
    ...field,
    value: inputValueToValue(value),
    onChange: (value: any) => {
      onChange(valueToInputValue(value))
    },
  }
}

const getDefaultValue = (type?: GenericFormFieldType) => {
  switch (type) {
    case 'number':
      return 0
    case 'boolean':
      return false
    case 'strings':
      return []
    case 'nullableNonNegativeNumber':
      return null
    case 'string':
    default:
      return ''
  }
}

export const getDefaultValues = (config: GenericFormConfig) => {
  return config.reduce(
    (acc, { name, type }) => {
      acc[name] = getDefaultValue(type)
      return acc
    },
    {} as { [key: string]: any }
  )
}

export const getFieldParams = (
  config: GenericFormConfig,
  t: (key: string) => string,
  t_key: string
) => {
  return config.map(({ name, type, label, containerProps, ...rest }) => ({
    name,
    label: label || t(`${t_key}.${name}`),
    ...(type === 'number' && genericFormNumberFieldParams),
    ...(type === 'nullableNonNegativeNumber' &&
      genericFormNullableNonNegativeNumberFieldParams),
    ...(type === 'boolean' && genericFormBooleanFieldParams),
    containerProps: {
      ...containerProps,
      className: cn(
        containerProps?.className,
        type === 'number' &&
          genericFormNumberFieldParams.containerProps?.className,
        type === 'boolean' &&
          genericFormBooleanFieldParams.containerProps?.className
      ),
    },
    ...rest,
  }))
}

export type GenericFormFieldParams<T> = {
  name: Path<T>
  type?: GenericFormFieldType
  label: string
  FieldComponent?: ComponentType<any>
  containerProps?: Object & { className?: string }
  valueToInputValue?(value: any): any
  inputValueToValue?(value: any): any
  required?: boolean
  getComponentFieldProps?: (props: GetComponentFieldPropsProps) => Object
}

const getSelectEnumFieldProps = (props: GetComponentFieldPropsProps) => {
  const { value, onChange } = defaultGetComponentFieldProps(props)
  return {
    className: '!tw-my-0',
    value,
    onChange,
    showLabel: false,
  }
}

export const genericFormSelectEnumFieldParams: Partial<
  Omit<GenericFormFieldParams<any>, 'name'>
> = {
  getComponentFieldProps: getSelectEnumFieldProps,
}

export const genericFormMultiSelectEnumFieldParams: Partial<
  Omit<GenericFormFieldParams<any>, 'name'>
> = {
  getComponentFieldProps: getSelectEnumFieldProps,
  type: 'strings',
}

export const genericFormNumberFieldParams: Partial<
  Omit<GenericFormFieldParams<any>, 'name'>
> = {
  FieldComponent: InputNumber,
  getComponentFieldProps: (props: GetComponentFieldPropsProps) => {
    const defaultFieldProps = defaultGetComponentFieldProps(props)
    return {
      ...defaultFieldProps,
      className: 'tw-w-full',
    }
  },
}

export const genericFormNullableNonNegativeNumberFieldParams: Partial<
  Omit<GenericFormFieldParams<any>, 'name'>
> = {
  FieldComponent: InputNumber,
  getComponentFieldProps: (props: GetComponentFieldPropsProps) => {
    const defaultFieldProps = defaultGetComponentFieldProps(props)
    return {
      ...defaultFieldProps,
      className: 'tw-w-full',
      min: 0,
    }
  },
}

export const genericFormBooleanFieldParams: Partial<
  Omit<GenericFormFieldParams<any>, 'name'>
> = {
  FieldComponent: Switch,
  containerProps: {
    className: 'tw-flex tw-flex-row tw-gap-4 tw-items-center',
  },
  getComponentFieldProps: (props: GetComponentFieldPropsProps) => {
    const defaultFieldProps = defaultGetComponentFieldProps(props)
    return {
      ...defaultFieldProps,
      className: '!tw-my-0',
      checked: !!props.value,
      onCheckedChange: (value: boolean) => {
        props.onChange(value)
      },
    }
  },
}

const defaultUseFormProps: Partial<UseFormProps<any>> = {
  mode: 'onChange',
  shouldFocusError: false,
}

type useGenericSchemaProps = {
  config: GenericFormConfig
}

const useSchema = ({ config }: useGenericSchemaProps) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'Forms.errors',
  })

  const schema = useMemo(() => {
    const shape = config.reduce((acc, { name, type, required }) => {
      let baseSchema: yup.Schema = yup.string()
      switch (type) {
        case 'number':
          baseSchema = yup.number().typeError(t('number'))
          break
        case 'nullableNonNegativeNumber':
          baseSchema = yup
            .number()
            .nullable()
            .transform((_, value) => {
              return value === '' ? null : !value ? null : Number(value)
            })
            .test('isNumber', t('number'), (value) => {
              if (value === null) return true

              const valueAsNumber = Number(value)

              if (isNaN(valueAsNumber)) return false

              return true
            })
            .min(0, t('min_number', { min: 0 }))
          break
        case 'boolean':
          baseSchema = yup.boolean()
          break
        case 'strings':
          baseSchema = yup.array().of(yup.string())
          break
      }

      acc[name] = required ? baseSchema.required() : baseSchema.optional()

      return acc
    }, {} as yup.ObjectShape)
    return yup.object().shape(shape)
  }, [config, t])

  return { schema }
}

export type GenericFormProps<T extends FieldValues> = {
  config: GenericFormConfig
  onSubmit: (values: T) => void
  values: T
  t: (key: string) => string
  t_key: string
  context?: any
  useFormProps?: Partial<UseFormProps<any>>
  submitOnChange?: boolean
}
export const GenericForm = <T extends FieldValues>({
  config,
  onSubmit,
  values,
  t,
  t_key,
  context,
  useFormProps = defaultUseFormProps,
  submitOnChange = true,
}: GenericFormProps<T>) => {
  const { schema } = useSchema({ config })
  const resolver = yupResolver(schema)
  const defaultValues = getDefaultValues(config)
  const fieldsParams = getFieldParams(config, t, t_key)
  const form = useForm<any>({
    ...useFormProps,
    defaultValues: {
      ...useFormProps?.defaultValues,
      ...defaultValues,
      ...values,
    },
    resolver,
  })

  const onInvalid = (errors: FieldErrors<T>) => {
    console.log({ errors })
  }

  useEffect(() => {
    if (submitOnChange) {
      const { unsubscribe } = form.watch((value, { type }) => {
        if (type !== 'change') return
        form.handleSubmit(onSubmit, onInvalid)()
      })
      return () => unsubscribe()
    }
  }, [submitOnChange, form.watch])

  const formValues = form.watch()

  return (
    <Form {...form}>
      <form
        className="tw-grid tw-grid-cols-6 tw-gap-4 tw-items-baseline"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        {fieldsParams.map(
          ({
            name,
            label,
            containerProps,
            FieldComponent = Input,
            valueToInputValue = (v: any) => v,
            inputValueToValue = (v: any) => v,
            required,
            condition,
            getComponentFieldProps = defaultGetComponentFieldProps,
          }) => {
            let containerClassName = containerProps?.className || ''
            if (!containerClassName.includes('tw-col-span-'))
              containerClassName = cn(containerClassName, 'tw-col-span-3')
            return (
              <FormField
                key={name}
                control={form.control}
                name={name}
                render={({ field: { value, onChange, ...field } }) => {
                  const props = getComponentFieldProps({
                    field,
                    inputValueToValue,
                    value,
                    onChange,
                    valueToInputValue,
                  })
                  const disabled =
                    condition != null && !condition(formValues, context)
                  if (disabled)
                    return (
                      <div {...containerProps} className={containerClassName} />
                    )

                  return (
                    <FormItem
                      {...containerProps}
                      className={containerClassName}
                    >
                      <FormLabel>
                        {label}
                        {required && ' *'}
                      </FormLabel>
                      <FormControl>
                        <FieldComponent {...props} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )
                }}
              />
            )
          }
        )}
      </form>
    </Form>
  )
}
