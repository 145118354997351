import * as Sentry from '@sentry/react'
import settings from 'src/settings'

export function configureSentry() {
  if (settings.sentry.dsn.length === 0) {
    return
  }

  Sentry.init({
    dsn: settings.sentry.dsn,
    environment: settings.sentry.environment,

    // Add optional integrations for additional features
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],

    tracePropagationTargets: [
      // Backend url
      settings.backendUrl,
    ],

    // Define how likely traces are sampled. Adjust this value in production, or use tracesSampler for greater control.
    tracesSampleRate: 1.0, // Capture 100% of the transactions

    // Define how likely Replay events are sampled.
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,

    // Define how likely Replay events are sampled when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    // Setting this option to true will print useful information to the console while you're setting up Sentry.
    debug: false,
  })
}
