import PasswordInput from '@/common/atoms/PasswordInput'
import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { useToast } from '@/components/ui/use-toast'
import { AgencyMemberFragment } from '@gql'
import React, { useMemo } from 'react'
import { FieldErrors, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { nhost } from 'src/nhost'
import {
  AdminSigninFormValues,
  useAdminSigninSchema,
} from '../hooks/useAdminSigninSchema'
import { yupResolver } from '@hookform/resolvers/yup'

type AdminSigninFormProps = {
  agencyMember: AgencyMemberFragment
  onSuccess?: () => void
}

export const AdminSigninForm = ({
  agencyMember,
  onSuccess,
}: AdminSigninFormProps) => {
  const { t } = useTranslation()
  const { toast } = useToast()

  const { schema } = useAdminSigninSchema()

  const defaultValues = useMemo(() => {
    return {
      email: agencyMember.email ?? '',
      ['new-password']: '',
      ['confirm-password']: '',
    }
  }, [agencyMember])

  const form = useForm<AdminSigninFormValues>({
    resolver: yupResolver(schema),
    defaultValues,
  })

  const onSubmit = async ({
    'new-password': password,
    'confirm-password': confirm,
  }: AdminSigninFormValues) => {
    // Set new password to authenticated user
    if (password && confirm) {
      const result = await nhost.auth.changePassword({ newPassword: password })

      if (result.error) {
        console.error(result.error)
        toast({
          variant: 'destructive',
          title: t('AdminSigninForm.toast.changePassword.error.title'),
          description: t(
            'AdminSigninForm.toast.changePassword.error.description'
          ),
        })
        return
      } else {
        onSuccess?.()
      }
      // Refresh user data
      await nhost.auth.refreshSession()
    }
  }

  const onError = (errors: FieldErrors<AdminSigninFormValues>) => {
    console.error(errors)
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit, onError)}
        className="tw-space-y-5"
      >
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('AdminSigninForm.email')}</FormLabel>
              <FormControl>
                <Input
                  placeholder={t('AdminSigninForm.emailPlaceholder')}
                  disabled={!!agencyMember.email}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="new-password"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('AdminSigninForm.password')} *</FormLabel>
              <FormControl>
                <PasswordInput
                  id="new-password"
                  required
                  autoComplete="new-password"
                  showRequirements
                  {...field}
                />
              </FormControl>

              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="confirm-password"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('AdminSigninForm.confirm')} *</FormLabel>
              <FormControl>
                <PasswordInput
                  id="confirm-password"
                  required
                  autoComplete="confirm-password"
                  {...field}
                />
              </FormControl>

              <FormMessage />
            </FormItem>
          )}
        />
        <Button type="submit" variant="green">
          {t('common.save')}
        </Button>
      </form>
    </Form>
  )
}
