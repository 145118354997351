import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

export const useEstimateEstateSchema = () => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'Forms.errors',
  })

  const schema = useMemo(
    () =>
      yup.object().shape({
        startEstimate: yup
          .number()
          .typeError(t('number'))
          .required(t('required')),
        endEstimate: yup
          .number()
          .typeError(t('number'))
          .required(t('required')),
      }),
    [t]
  )

  return { schema }
}

export type EstimateEstateFormValues = yup.InferType<
  ReturnType<typeof useEstimateEstateSchema>['schema']
>
