import { Loading } from '@/common/atoms/Loading'
import TextError from '@/common/atoms/TextError'
import Page404 from '@/common/pages/Page404'
import { useSellerBySlugSubscription, useSellerSubscription } from '@gql'
import { useStoreActions } from '@store/hooks'
import React, { Suspense, useEffect } from 'react'
import { Route, Routes, useNavigate, useParams } from 'react-router-dom'
import { EstateRoute } from './EstateRoute'
import { SellerPage } from '@/seller/pages/SellerPage'
import { MembersPage } from '@/sellerMember/pages/MembersPage'
import { UserInfoPage } from '@/user/pages/UserInfoPage'
import useIsAgencyMember from '@/agencyMember/hooks/useIsAgencyMember'
import { SellerEstatesPage } from '@/estate/pages/SellerEstatesPage'
import { useHasuraClaim } from '@nhost/react'

type Params = {
  sellerId: string
  slug: string
}

export default function SellerRoute() {
  const isAgencyMember = useIsAgencyMember()
  const { sellerId, slug } = useParams<Params>()
  const sellerMemberSellerId = useHasuraClaim('seller-member-seller-id')

  const navigate = useNavigate()
  useEffect(() => {
    if (sellerId) return
    if (slug) return
    if (sellerMemberSellerId) navigate(`/sellers/${sellerMemberSellerId}/`)
  }, [sellerId, slug])

  // Subscribe to seller structure
  // either by id or slug
  const {
    data: dataId,
    error: errorId,
    loading: loadingId,
  } = useSellerSubscription({
    skip: !sellerId,
    variables: { id: sellerId! },
  })
  const {
    data: dataSlug,
    error: errorSlug,
    loading: loadingSlug,
  } = useSellerBySlugSubscription({
    skip: !slug,
    variables: { slug: slug! },
  })

  const data = dataId?.seller_by_pk ?? dataSlug?.seller[0]
  const error = errorId ?? errorSlug
  const loading = loadingId || loadingSlug

  const actions = useStoreActions((actions) => ({
    setCurrentId: actions.seller.setCurrentId,
    setSubscriptionResult: actions.seller.setSubscriptionResult,
  }))

  // Set current id in store instantly from URL params
  useEffect(() => {
    if (!sellerId) return
    actions.setCurrentId(sellerId)
  }, [sellerId])

  // Set current id in store when using a slug and seller is loaded
  useEffect(() => {
    if (!slug && !data?.id) return
    actions.setCurrentId(data?.id)
  }, [slug, data?.id])

  // Reset current id on unmount
  useEffect(() => {
    return () => actions.setCurrentId(undefined)
  }, [])

  // Set current state in store
  useEffect(() => {
    actions.setSubscriptionResult({
      result: data ?? undefined,
      loading,
      error,
    })
  }, [data, error, loading])

  return (
    <Suspense fallback={<Loading center active />}>
      <Loading center active={loading} />
      {error && <TextError error={error} />}

      {!data && !loading ? (
        <Page404 />
      ) : (
        <Routes>
          {(sellerId || data?.id) && isAgencyMember ? (
            <Route index element={<SellerPage />} />
          ) : (
            <Route index element={<SellerEstatesPage />} />
          )}
          <Route path="estates/:estateId/*" element={<EstateRoute />} />
          <Route path="seller-members" element={<MembersPage />} />
          <Route path="seller-infos" element={<SellerPage />} />
          <Route path="user-info/:userId" element={<UserInfoPage />} />
          <Route path="*" element={<Page404 />} />
        </Routes>
      )}
    </Suspense>
  )
}
