import { Command, CommandItem, CommandList } from '@/components/ui/command'
import { cn } from '@/lib/utils'
import { Command as CommandPrimitive } from 'cmdk'
import React, { ChangeEvent, FocusEvent, ForwardedRef, useState } from 'react'

type AutoCompleteProps<
  TItem extends {
    id: string
    label: string
  },
> = {
  className?: string
  placeholder?: string
  disabled?: boolean
  name?: string
  value?: string
  items: TItem[]
  onInput: (event: ChangeEvent<HTMLInputElement>) => void
  onSelectItem: (item: TItem) => void
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void
}

function AutoCompleteInner<TItem extends { id: string; label: string }>(
  {
    className,
    placeholder,
    disabled,
    name,
    value,
    items,
    onInput,
    onSelectItem,
    onBlur,
  }: AutoCompleteProps<TItem>,
  ref: ForwardedRef<HTMLInputElement>
) {
  const [open, setOpen] = useState(false)

  const handleInput = (event: ChangeEvent<HTMLInputElement>) => {
    setOpen(true)
    onInput(event)
  }

  const handleSelect = (item: any) => {
    setOpen(false)
    onSelectItem(item)
  }

  const handleFocus = (event: FocusEvent<HTMLInputElement>) => {
    // setOpen(true)
  }

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    setTimeout(() => setOpen(false), 100)
    onBlur?.(event)
  }

  return (
    <Command
      className={cn(className, 'tw-relative tw-overflow-visible !tw-h-auto')}
    >
      <div
        className="tw-flex tw-items-center"
        // eslint-disable-next-line react/no-unknown-property
        cmdk-input-wrapper=""
      >
        <CommandPrimitive.Input
          ref={ref}
          className={cn(
            'tw-flex tw-h-10 tw-w-full tw-rounded-md tw-border tw-border-input tw-bg-background tw-px-3 tw-py-2 tw-text-sm tw-ring-offset-background file:tw-border-0 file:tw-bg-transparent file:tw-text-sm file:tw-font-medium file:tw-cursor-pointer placeholder:tw-text-muted-foreground focus-visible:tw-outline-none focus-visible:tw-ring-2 focus-visible:tw-ring-ring focus-visible:tw-ring-offset-2 disabled:tw-cursor-not-allowed disabled:tw-opacity-50 disabled:tw-bg-zinc-50',
            className
          )}
          placeholder={placeholder}
          disabled={disabled}
          name={name}
          value={value}
          onInput={handleInput}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onKeyDown={(event) => {
            switch (event.key) {
              // NOTE: prevents unwanted cmdk behaviour
              case 'Home':
              case 'End':
                event.stopPropagation()
                break
            }
          }}
        />
      </div>
      <CommandList
        inputMode="none"
        className={cn(
          'tw-z-50 tw-absolute tw-top-[100%] tw-bg-background',
          'tw-rounded-md tw-border tw-border-input',
          'tw-my-2',
          (!open || items.length <= 0) && 'tw-hidden'
        )}
      >
        {/* <CommandEmpty>No results found.</CommandEmpty> */}
        {items.map((item, index) => (
          <CommandItem
            key={item.id}
            onSelect={() => handleSelect(item)}
            onClick={() => handleSelect(item)}
            className="tw-cursor-pointer tw-bg-white"
          >
            {item.label}
          </CommandItem>
        ))}
      </CommandList>
    </Command>
  )
}

export const AutoComplete = React.forwardRef(AutoCompleteInner) as <
  TItem extends { id: string; label: string },
>(
  props: AutoCompleteProps<TItem> & {
    ref?: React.ForwardedRef<HTMLInputElement>
  }
) => ReturnType<typeof AutoCompleteInner>
