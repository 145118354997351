import {
  AgencyMemberFragment,
  AgencyFragment,
  AgencyWithMembersAndSellersFragment,
  SellerFragment,
} from '@gql'
import { Action, action } from 'easy-peasy'

interface AgencyModel {
  currentId: string | undefined
  current: AgencyFragment | undefined
  agencyMembers: AgencyMemberFragment[] | undefined
  sellers: SellerFragment[] | undefined
  loading: boolean
  error: Error | undefined
  // Set Id instantly from URL params
  setCurrentId: Action<AgencyModel, string | undefined>
  // Set current after loading
  setSubscriptionResult: Action<
    AgencyModel,
    {
      result: AgencyWithMembersAndSellersFragment | undefined
      loading: boolean
      error: Error | undefined
    }
  >
}

const model: AgencyModel = {
  currentId: undefined,
  current: undefined,
  agencyMembers: undefined,
  sellers: undefined,
  loading: false,
  error: undefined,

  setCurrentId: action((state, id) => {
    if (state.currentId !== id) {
      state.currentId = id
      state.agencyMembers = undefined
    }
  }),

  setSubscriptionResult: action((state, { result, loading, error }) => {
    if (result) {
      const { agency_members, sellers, ...current } = result
      state.current = current

      // Get and sort members
      state.agencyMembers = [...agency_members].sort((a, b) => {
        // Sort by name or email if name is undefined
        if (a?.name && b?.name) return a.name?.localeCompare(b.name) || 0
        if (a?.email && b?.email) return a.email?.localeCompare(b.email) || 0
        return 0
      })

      state.sellers = sellers
    }
    state.loading = loading
    state.error = error
  }),
}

export default model
