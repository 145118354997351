export const getUniqueFilterByKey =
  <T = any>(key: keyof T) =>
  (currentItem: T, index: number, array: Array<T>) =>
    array.findIndex((item) => item[key] === currentItem[key]) === index

export const getUniqueFilterById = () => getUniqueFilterByKey('id')
export const uniqueFilter = <T = any>(
  currentItem: T,
  index: number,
  array: Array<T>
) => array.findIndex((item) => item === currentItem) === index
