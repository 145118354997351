import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { InputDatePicker } from '@/components/ui/InputDatePicker'
import { InputPrice } from '@/components/ui/inputPrice'
import { TimePicker } from '@/components/ui/timePicker'
import { useToast } from '@/components/ui/use-toast'
import {
  EstateSettingOpeningFormValues,
  useEstateSettingOpeningFormSchema,
} from '@/estate/hooks/useEstateSettingOpeningForm'
import {
  WebappEstateFragment,
  useUpdateEstateMutation,
  useUpdateEstateRoundMutation,
} from '@gql'
import { yupResolver } from '@hookform/resolvers/yup'
import { parseDate } from '@utils/dates'
import { format } from 'date-fns'
import debounce from 'lodash.debounce'
import { InfoIcon } from 'lucide-react'
import React, { useEffect, useMemo } from 'react'
import { FieldErrors, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { trpc } from 'src/trpc'

type EstateSettingOpeningFormProps = {
  estate: WebappEstateFragment
  readOnly?: boolean
}

export const EstateSettingOpeningForm = ({
  estate,
  readOnly,
}: EstateSettingOpeningFormProps) => {
  const { t } = useTranslation()
  const { toast } = useToast()
  const [updateEstate] = useUpdateEstateMutation({
    refetchQueries: ['getEstates'],
  })

  const [updateEstateRound] = useUpdateEstateRoundMutation({
    refetchQueries: ['getEstates'],
  })

  const debounceUpdateEstate = debounce(updateEstate, 500)
  const { schema } = useEstateSettingOpeningFormSchema()

  const currentRound = useMemo(() => {
    return estate.estateRounds.reduce(
      (acc, round) => (round.number < acc.number ? round : acc),
      estate.estateRounds[0]
    )
  }, [estate])

  const defaultValues = useMemo(() => {
    const defaultStartDate = new Date()
    defaultStartDate.setHours(9, 0, 0, 0)
    const startDate = parseDate(currentRound?.startDate) ?? defaultStartDate
    const defaultEndDate = new Date()
    defaultEndDate.setHours(18, 0, 0, 0)
    defaultEndDate.setMonth(defaultEndDate.getMonth() + 1)
    const endDate = parseDate(currentRound?.endDate) ?? defaultEndDate
    const firstPriceRaw = estate.firstPrice ?? 0
    const firstPrice = isNaN(Number(firstPriceRaw)) ? 0 : Number(firstPriceRaw)
    const reservePriceRaw = estate.reservePrice
    const reservePrice = reservePriceRaw
      ? isNaN(Number(reservePriceRaw))
        ? undefined
        : Number(reservePriceRaw)
      : undefined

    return {
      firstPrice,
      reservePrice,
      startDate,
      endDate,
      startTime: format(startDate, 'HH:mm'),
      endTime: format(endDate, 'HH:mm'),
    }
  }, [estate])

  const form = useForm<EstateSettingOpeningFormValues>({
    resolver: yupResolver(schema),
    defaultValues,
  })

  const onSubmit = async ({
    startTime,
    startDate,
    endTime,
    endDate,
    firstPrice,
    reservePrice,
  }: EstateSettingOpeningFormValues) => {
    const [startHour, startMinute] = startTime.split(':').map(Number)
    startDate.setHours(startHour, startMinute, 0, 0)

    const [endHour, endMinute] = endTime.split(':').map(Number)
    endDate.setHours(endHour, endMinute, 0, 0)

    await debounceUpdateEstate({
      variables: {
        id: estate.id,
        values: {
          firstPrice: firstPrice ?? null,
          reservePrice: reservePrice ?? null,
        },
      },
      onCompleted: () => {
        toast({
          variant: 'success',
          description: t('EstateSettingOpeningForm.toast.success'),
        })
      },
      onError: (error) => {
        toast({
          variant: 'destructive',
          title: t('EstateSettingOpeningForm.toast.error'),
          description: error.message,
        })
      },
    })

    if (currentRound) {
      await updateEstateRound({
        variables: {
          id: currentRound.id,
          values: {
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString(),
          },
        },
        onCompleted: () => {
          toast({
            variant: 'success',
            description: t('EstateSettingOpeningForm.toast.success'),
          })
        },
        onError: (error) => {
          toast({
            variant: 'destructive',
            title: t('EstateSettingOpeningForm.toast.error'),
            description: error.message,
          })
        },
      })
    } else {
      try {
        await trpc.estate.newRound.mutate({
          estateId: estate.id,
          endDate,
        })
        toast({
          title: t(
            'EstateSettingOffersForm.newRound.toast.success.title'
          ) as string,
          description: t(
            'EstateSettingOffersForm.newRound.toast.success.description'
          ) as string,
          variant: 'success',
        })
      } catch (error) {
        console.error(error)
        toast({
          title: t(
            'EstateSettingOffersForm.newRound.toast.error.title'
          ) as string,
          description: t(
            'EstateSettingOffersForm.newRound.toast.error.description'
          ) as string,
          variant: 'destructive',
        })
      }
    }
  }

  const onInvalid = (errors: FieldErrors<EstateSettingOpeningFormValues>) => {
    console.log({ errors })
  }

  useEffect(() => {
    const { unsubscribe } = form.watch((value, { type }) => {
      if (type !== 'change') return
      form.handleSubmit(onSubmit, onInvalid)()
    })
    return () => unsubscribe()
  }, [form.watch])

  useEffect(() => {
    // auto submit form with default values if first round start date is not set
    if (currentRound?.startDate) return
    form.handleSubmit(onSubmit, onInvalid)()
  }, [currentRound])

  return (
    <Form {...form}>
      <form className="tw-space-y-6 tw-pt-6 tw-h-full">
        <FormField
          control={form.control}
          name="firstPrice"
          render={({ field: { onChange, ...field } }) => {
            const handleValueChange = (value: string | undefined) => {
              onChange(value)
            }
            return (
              <FormItem>
                <FormLabel>
                  {t('EstateSettingOpeningForm.firstPrice')}
                </FormLabel>
                <FormControl>
                  <InputPrice
                    step={1}
                    suffix="€"
                    value={field.value}
                    onValueChange={handleValueChange}
                    disabled={readOnly}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )
          }}
        />
        <FormField
          control={form.control}
          name="reservePrice"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                {t('EstateSettingOpeningForm.reservePrice')}
              </FormLabel>
              <FormControl>
                <InputPrice
                  step={1}
                  suffix="€"
                  {...field}
                  disabled={readOnly}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        {!readOnly && (
          <div className="tw-py-3 tw-p-4 tw-bg-green-200 tw-rounded-lg">
            <p className="tw-text-sm tw-font-normal tw-text-green-700 tw-flex tw-items-center">
              <InfoIcon strokeWidth={3} className="tw-mr-3" />
              {t('EstateSettingOpeningForm.description', {
                price: form.getValues('firstPrice') || '?',
              })}
            </p>
          </div>
        )}
        <div className="tw-flex tw-space-x-4 ">
          <FormField
            control={form.control}
            name="startDate"
            render={({ field }) => (
              <FormItem className="tw-flex tw-flex-col tw-w-full">
                <FormLabel>
                  {t('EstateSettingOpeningForm.startDate.date')}
                </FormLabel>
                <InputDatePicker
                  {...field}
                  fromYear={1900}
                  toYear={2100}
                  disabled={readOnly}
                />
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="startTime"
            render={({ field }) => (
              <FormItem className="tw-flex tw-flex-col tw-w-full">
                <FormLabel>
                  {t('EstateSettingOpeningForm.startDate.hour')}
                </FormLabel>
                {/* TODO make a component with InputDatePicker that works with dates */}
                <TimePicker {...field} disabled={readOnly} />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className="tw-flex tw-space-x-4">
          <FormField
            control={form.control}
            name="endDate"
            render={({ field }) => (
              <FormItem className="tw-flex tw-flex-col tw-w-full">
                <FormLabel>
                  {t('EstateSettingOpeningForm.endDate.date')}
                </FormLabel>
                <InputDatePicker
                  {...field}
                  fromYear={1900}
                  toYear={2100}
                  disabled={readOnly}
                />
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="endTime"
            render={({ field }) => (
              <FormItem className="tw-flex tw-flex-col tw-w-full">
                <FormLabel>
                  {t('EstateSettingOpeningForm.endDate.hour')}
                </FormLabel>
                {/* TODO make a component with InputDatePicker that works with dates */}
                <TimePicker {...field} disabled={readOnly} />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      </form>
    </Form>
  )
}
