import { useEmailSchema } from '@/common/hooks/schemas/useEmailSchema'
import { usePasswordSchema } from '@/common/hooks/schemas/usePasswordSchema'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

export const useLoginSchema = () => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'Forms.errors',
  })
  const { schema: emailSchema } = useEmailSchema()
  const { schema: passwordSchema } = usePasswordSchema()

  const schema = useMemo(
    () =>
      yup.object().shape({
        email: emailSchema.required(t('required')),
        ['current-password']: passwordSchema.required(t('required')),
      }),
    [t]
  )

  return { schema }
}

export type LoginFormValues = yup.InferType<
  ReturnType<typeof useLoginSchema>['schema']
>
