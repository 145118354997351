export interface EstateFeatures {
  building?: string
  propertyFloor?: number
  door?: string
  numberOfFloors?: number
  constructionYear?: number
  carrezLawSurface?: number
  habitableSurface?: number
  propertyDescription?: string
  marketingDescription?: string
  primaryPurpose?: string
  landSurface?: number
  gardenSurface?: number
  balconyTerraceSurface?: number
  pool?: boolean
  furnished?: boolean
  numberOfRooms?: number
  numberOfBedrooms?: number
  numberOfBathrooms?: number
  numberOfGarages?: number
  numberOfParkings?: number
  cellar?: boolean
  orientation?: string[]
  equipments?: string[]
  inDemandingArea?: boolean
  areaDemandLevel?: string
  propertyFreeOfOccupation?: boolean
  occupationType?: string
  accessDetails?: string
  propertyTaxAmount?: number
  housingTaxAmount?: number
  annualChargesAmount?: number
  urbanPreemptionRightType?: string
  dpuClearanceTime?: string
}
export interface EstateSellerData {
  singleRepresentativeForMultipleSellers?: boolean
  mandatedSellerWithPowerDelegation?: string
}

export interface EstateCoownership {
  isCoowned?: string
  syndicExtranetURL?: string
  syndicExtranetLogin?: string
  syndicExtranetPassword?: string
  totalLotsNumber?: string
  ongoingProcedures?: string
} // TODO better typing

export interface EstateServicesAndConditions {
  overallCondition?: string
  bathroomShowerServices?: string
  bathroomShowerCondition?: string
  kitchenServices?: string
  kitchenCondition?: string
  flooringServices?: string
  flooringCondition?: string
  windowServices?: string
  windowCondition?: string
  masonryServices?: string
  masonryCondition?: string
  roofingServices?: string
  roofingCondition?: string
} // TODO better typing

export enum EPC_Rank_Enum {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
}

export interface EstateDiagnosticsData {
  asbestos?: boolean
  asbestosDate?: Date
  asbestosValidityDate?: Date
  carrez?: boolean
  carrezArea?: number
  carrezFloorArea?: number
  carrezDate?: Date
  epc?: boolean
  epcConsumption?: number
  epcEmission?: number
  epcDate?: Date
  epcConsumptionRank?: EPC_Rank_Enum
  epcEmissionRank?: EPC_Rank_Enum
  elec?: boolean
  elecDate?: Date
  elecValidityDate?: Date
  fungalDecay?: boolean
  fungalDecayDate?: Date
  gas?: boolean
  gasDate?: Date
  gasValidityDate?: Date
  lead?: boolean
  leadDate?: Date
  leadValidityDate?: Date
  noise?: boolean
  noiseDate?: Date
  noiseValidityDate?: Date
  ntr?: boolean
  ntrDate?: Date
  ntrValidityDate?: Date
  sanitation?: boolean
  sanitationDate?: Date
  sanitationValidityDate?: Date
  termites?: boolean
  termitesDate?: Date
  termitesValidityDate?: Date
} // TODO better typing

export interface EstateVirtualVisit {
  url: string
}

export interface EstateVirtualVisits {
  list?: EstateVirtualVisit[]
}
