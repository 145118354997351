import { useEmailSchema } from '@/common/hooks/schemas/useEmailSchema'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

export const useForgotPasswordSchema = () => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'Forms.errors',
  })
  const { schema: emailSchema } = useEmailSchema()

  const schema = yup.object().shape({
    email: emailSchema.required(t('required')),
  })

  return { schema }
}

export type ForgotPasswordFormValues = yup.InferType<
  ReturnType<typeof useForgotPasswordSchema>['schema']
>
