import React from 'react'
import { Star } from 'lucide-react'
import { Button, ButtonProps } from '@/components/ui/button'

export type StarButtonIconProps = ButtonProps & {
  isActive?: boolean
  willBeActive?: boolean
}

export function StarButtonIcon({
  isActive,
  willBeActive,
  ...props
}: StarButtonIconProps) {
  const starProps = {
    fill: isActive ? '#fdc402' : 'transparent',
    color: isActive || willBeActive ? '#fdc402' : '#b8b8b8',
  }
  return (
    <Button aria-label="Star" variant="ghost" size="icon" {...props}>
      <Star className="tw-h-4 tw-w-4" {...starProps} />
    </Button>
  )
}
