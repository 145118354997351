import useQueryParams from '@/common/hooks/useQueryParams'
import React, { useEffect, useState } from 'react'
import { MemberSigninForm } from '@/sellerMember/components/MemberSigninForm'
import { Navigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Title } from '@/common/atoms/Title'
import { trpc } from 'src/trpc'
import { useToast } from '@/components/ui/use-toast'
import { SellerMemberAndItsSellerTypeFragment } from '@almaris/backend/src/gql'
import { useSellerSubscription } from '@gql'
import { Loading } from '@/common/atoms/Loading'
import { useHasuraClaim } from '@nhost/react'
import { ErrorMessage } from '@/common/atoms/ErrorMessage'

type Params = {
  id: string
  token: string
}

export default function MemberInvitationPage() {
  const sellerMemberId = useHasuraClaim('seller-member-id')
  const isSellerMember = !!sellerMemberId && sellerMemberId !== 'null'

  const { t } = useTranslation()
  const { toast } = useToast()

  const { sellerId } = useParams<{ sellerId: string }>()
  const { id: memberId, token } = useQueryParams<Params>()
  const { data, loading } = useSellerSubscription({
    skip: !sellerId,
    variables: { id: sellerId! },
  })
  const seller = data?.seller_by_pk

  const [isLoading, setIsLoading] = useState(false)

  const [currentMember, setCurrentMember] = useState<
    SellerMemberAndItsSellerTypeFragment | undefined
  >(undefined)

  // Accept invitation
  useEffect(() => {
    if (!memberId) return
    if (!token) return
    setIsLoading(true)
    setTimeout(() => {
      trpc.member.acceptMemberInvitation
        .mutate({ memberId, token })
        .then((data) => {
          setCurrentMember(data)
        })
        .catch((error) => {
          console.error(error, JSON.stringify(error))
          toast({
            variant: 'destructive',
            title: t('common.error'),
            description: error?.response?.data || error?.message || undefined,
          })
        })
        .finally(() => {
          setIsLoading(false)
        })
    }, 2000) // Quick fix: wait for Nhost Auth to be ready
  }, [memberId, token])

  if (isSellerMember && !loading) {
    if (!seller) return <ErrorMessage>Unabled to load Seller</ErrorMessage> // TODO translate
    return <Navigate to={`/${seller.slug}/`} />
  }

  return (
    <div className="tw-space-y-5 tw-mb-3">
      <Title>{t('MemberInvitationPage.title')}</Title>
      {(isLoading || loading || !currentMember || !seller) && (
        <Loading active />
      )}
      {!(isLoading || loading) && currentMember && seller && (
        <>
          <h1 className="tw-text-2xl tw-font-bold">
            {t('MemberInvitationPage.heading', {
              agency: seller?.agency?.name,
            })}
          </h1>
          <p className="tw-text-sm">
            {t('MemberInvitationPage.description', {
              seller: seller?.name,
            })}
          </p>

          <MemberSigninForm
            member={currentMember}
            onSuccess={() => setIsLoading(true)}
          />
        </>
      )}
    </div>
  )
}
