import { differenceInMinutes } from 'date-fns'

export const getTotalTimeSlots = (
  spans: { startTime?: Date; endTime?: Date }[]
) => {
  return spans.reduce((total, { startTime, endTime }) => {
    if (!startTime || !endTime) return total
    return total + calculateTimeSlots(startTime, endTime)
  }, 0)
}

const calculateTimeSlots = (start: Date, end: Date, step: number = 30) => {
  if (!start || !end) return 0
  const diff = differenceInMinutes(end, start)
  return diff > 0 ? Math.floor(diff / step) : 0
}
