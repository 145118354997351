import { Button } from '@/components/ui/button'
import { Input, InputProps } from '@/components/ui/input'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
  TooltipArrow,
} from '@/components/ui/tooltip'
import { cn } from '@/lib/utils'
import { EyeIcon, EyeOffIcon } from 'lucide-react'
import React, { useState, forwardRef, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { CheckIcon, XIcon } from 'lucide-react'
import { twMerge } from 'tailwind-merge'

export type PasswordInputProps = InputProps & {
  showRequirements?: boolean
  requirements?: {
    key: string
    label: string
    rule: (password: string) => boolean
  }[]
}

const PasswordInput = forwardRef<HTMLInputElement, PasswordInputProps>(
  (
    {
      className,
      onChange,
      showRequirements,
      requirements: _requirement,
      value: valueProp = '',
      ...props
    },
    ref
  ) => {
    const { t } = useTranslation()

    const requirements = useMemo(() => {
      // TODO centralize password rules with yup schemas
      return (
        _requirement ?? [
          {
            key: 'PasswordInput.requirement.length',
            label: t('PasswordInput.requirement.length'),
            rule: (password: string) => password.length > 8,
          },
          {
            key: 'PasswordInput.requirement.lowercaseUppercase',
            label: t('PasswordInput.requirement.lowercaseUppercase'),
            rule: (password: string) =>
              /[a-z]/.test(password) && /[A-Z]/.test(password),
          },
          {
            key: 'PasswordInput.requirement.number',
            label: t('PasswordInput.requirement.number'),
            rule: (password: string) => /\d/.test(password),
          },
          {
            key: 'PasswordInput.requirement.symbol',
            label: t('PasswordInput.requirement.symbol'),
            rule: (password: string) => /[!?&#$*]/.test(password),
          },
        ]
      )
    }, [_requirement, t])
    const [show, setShow] = useState(false)
    const [showButton, setShowButton] = useState(false)
    const [value, setValue] = useState<string | undefined>(String(valueProp))
    useEffect(() => {
      setValue(String(valueProp))
    }, [valueProp])

    const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
      setShowButton(() => e.target.value.length > 0)
      setValue(e.target.value)
      onChange?.(e)
    }

    const handleShowClick = (e: React.MouseEvent) => {
      e.preventDefault()
      setShow((s) => !s)
    }

    return (
      <div className="tw-relative">
        <div className="tw-relative">
          <Input
            type={show ? 'text' : 'password'}
            className={cn('tw-hide-password-toggle tw-pr-10', className)}
            onChange={handleChange}
            ref={ref}
            {...props}
          />

          {showButton && (
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    type="button"
                    variant="ghost"
                    size="icon"
                    className="tw-absolute tw-top-0 tw-right-0 tw-h-full tw-px-3 tw-py-2 hover:tw-bg-transparent"
                    onClick={handleShowClick}
                  >
                    {show ? <EyeOffIcon size={18} /> : <EyeIcon size={18} />}
                    <span className="tw-sr-only">
                      {t('PasswordInput.show')}
                    </span>
                  </Button>
                </TooltipTrigger>
                <TooltipContent>
                  <TooltipArrow />
                  <p>{t(show ? 'common.hide' : 'common.show')}</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          )}
        </div>

        {showRequirements && value && (
          <div className="tw-mt-2 tw-flex tw-flex-col">
            {requirements.map((c) => {
              const isOk = c.rule(value)
              const textColorClass = isOk
                ? 'tw-text-green-500'
                : 'tw-text-red-500'
              return (
                <div
                  key={c.key}
                  className={twMerge(
                    'tw-flex tw-items-center tw-gap-1 tw-text-sm',
                    textColorClass,
                    className
                  )}
                >
                  <span>{isOk ? <CheckIcon /> : <XIcon />}</span>
                  <span>{c.label}</span>
                </div>
              )
            })}
          </div>
        )}
      </div>
    )
  }
)

PasswordInput.displayName = 'PasswordInput'

export default PasswordInput
