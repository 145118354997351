import {
  ApplicationIdAndStatusFragment,
  useGetApplicationsStatusesByEstatesIdsQuery,
  useGetEstatesQuery,
  useGetVisitSpansSlotsIsVisitedByEstatesIdsQuery,
  VisitSpanSlotsIsVisitDoneFragment,
  WebappSimpleEstateFragment,
} from '@gql'
import { useMemo } from 'react'

export type EstateWithApplicationsAndVisitSpans = WebappSimpleEstateFragment & {
  applications: ApplicationIdAndStatusFragment[]
  visit_spans: VisitSpanSlotsIsVisitDoneFragment[]
}

export const useEstateWithApplicationsAndVisitSpans = () => {
  const {
    data: estatesData,
    loading: estatesLoading,
    error: estatesError,
  } = useGetEstatesQuery()

  const simpleEstates = estatesData?.estate ?? []

  const estateIds = useMemo(() => {
    return simpleEstates.map(({ id }) => id)
  }, [simpleEstates])

  const {
    data: applicationsData,
    loading: applicationsLoading,
    error: applicationsError,
  } = useGetApplicationsStatusesByEstatesIdsQuery({
    variables: {
      estateIds,
    },
    skip: !estateIds.length,
    onError: (error) => {
      console.error(error)
    },
  })
  const applicationsByEstateId = useMemo(() => {
    if (!applicationsData) return {}
    return applicationsData.application.reduce(
      (acc, application) => {
        if (!acc[application.estateId]) acc[application.estateId] = []
        acc[application.estateId].push(application)
        return acc
      },
      {} as { [key: string]: ApplicationIdAndStatusFragment[] }
    )
  }, [applicationsData])

  const {
    data: visitSpansData,
    loading: visitSpansLoading,
    error: visitSpansError,
  } = useGetVisitSpansSlotsIsVisitedByEstatesIdsQuery({
    variables: {
      estateIds,
    },
    skip: !estateIds.length,
    onError: (error) => {
      console.error(error)
    },
  })
  const visitSpansByEstateId = useMemo(() => {
    if (!visitSpansData) return {}
    return visitSpansData.visit_span.reduce(
      (acc, visit_span) => {
        if (!acc[visit_span.estateId]) acc[visit_span.estateId] = []
        acc[visit_span.estateId].push(visit_span)
        return acc
      },
      {} as { [key: string]: VisitSpanSlotsIsVisitDoneFragment[] }
    )
  }, [visitSpansData])

  const estates = useMemo(() => {
    return simpleEstates.map((estate) => {
      const applications = applicationsByEstateId[estate.id]
      const visit_spans = visitSpansByEstateId[estate.id]
      return {
        ...estate,
        applications,
        visit_spans,
      } as EstateWithApplicationsAndVisitSpans
    })
  }, [simpleEstates, applicationsByEstateId, visitSpansByEstateId])

  const loading = estatesLoading || applicationsLoading || visitSpansLoading
  const error = estatesError || applicationsError || visitSpansError

  return { estates, loading, error }
}
