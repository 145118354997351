import { nullableNumberSchema } from '@almaris/shared/schemas'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

export const useEstateSettingOpeningFormSchema = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'Forms.errors' })

  const schema = useMemo(
    () =>
      yup.object().shape({
        firstPrice: yup.number().typeError(t('number')).required(t('required')),
        reservePrice: nullableNumberSchema,
        startDate: yup.date().required(t('required')),
        startTime: yup.string().required(t('required')),
        endDate: yup.date().required(t('required')),
        endTime: yup.string().required(t('required')),
      }),
    [t]
  )

  return { schema }
}

export type EstateSettingOpeningFormValues = yup.InferType<
  ReturnType<typeof useEstateSettingOpeningFormSchema>['schema']
>
