import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

export const useSlugSchema = () => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'Forms.errors',
  })

  const schema = useMemo(
    () =>
      yup
        .string()
        .min(5, t('min', { min: 5 }))
        .max(30, t('max', { max: 30 }))
        .test('Slug', t('slug'), (slug = '') =>
          /^[a-z0-9]+(-[a-z0-9]+)*$/.test(slug)
        ),
    [t]
  )
  return {
    schema,
  }
}
