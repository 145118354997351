import { useToast } from '@/components/ui/use-toast'
import { ApolloError } from '@apollo/client'
import { useCreateEstateMediaMutation } from '@gql'
import { useTranslation } from 'react-i18next'

export const useCreateEstateMedia = () => {
  const { t } = useTranslation()
  const { toast } = useToast()

  const onError = (error: ApolloError) => {
    console.error(error)
    toast({
      title: t('useCreateEstateMedia.error.title'),
      description: t('useCreateEstateMedia.error.description'),
      variant: 'destructive',
    })
  }
  const refetchQueries = ['getEstateMedia']

  const [createEstateMedia, { data, loading, error }] =
    useCreateEstateMediaMutation({
      onError,
      refetchQueries,
    })

  return { createEstateMedia, data, loading, error }
}
