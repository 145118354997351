import React, { useState } from 'react'
import { Button } from '@/components/ui/button'
import { useTranslation } from 'react-i18next'
import { EstateMediaFileUploadDialog } from './EstateMediaFileUploadDialog'
import { EstateMediaTypeFragment, WebappEstateFragment } from '@gql'

export type NewEstateMediaProps = {
  estate: WebappEstateFragment
  type: EstateMediaTypeFragment
}
export const NewEstateMedia = ({ estate, type }: NewEstateMediaProps) => {
  const { t } = useTranslation()

  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className="tw-flex tw-flex-row tw-gap-4 tw-items-center">
      <div className="tw-w-full tw-bg-gray-100 tw-flex tw-flex-row tw-gap-4 tw-items-center tw-justify-between tw-rounded-lg tw-p-4">
        <div>{t('EstateMedias.addFile')}</div>
        <Button variant="outline" onClick={() => setIsOpen(true)}>
          {t('common.add')}
        </Button>
      </div>
      <EstateMediaFileUploadDialog
        estate={estate}
        showToasts={true}
        open={isOpen}
        onOpenChange={() => setIsOpen(false)}
        type={type}
        onUpload={() => setIsOpen(false)}
        canSetCustomName={true}
      />
    </div>
  )
}
