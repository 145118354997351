import { useNameSchema } from '@/common/hooks/schemas/useNameSchema'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

export const useEstateSettingLinkFormSchema = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'Forms.errors' })

  const { schema: nameSchema } = useNameSchema()

  const schema = useMemo(
    () =>
      yup.object().shape({
        links: yup
          .array()
          .of(
            yup.object().shape({
              id: yup.string().optional(),
              name: nameSchema.required({
                message: t('required'),
              }),
              url: yup.string().url(t('url')).optional(),
            })
          )
          .required(),
      }),
    [t]
  )

  return { schema }
}

export type EstateSettingLinkFormValues = yup.InferType<
  ReturnType<typeof useEstateSettingLinkFormSchema>['schema']
>
