import { Loading } from '@/common/atoms/Loading'
import { Button, buttonVariants } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { Progress } from '@/components/ui/progress'
import { useToast } from '@/components/ui/use-toast'
import { cn } from '@/lib/utils'
import {
  FileItemRef,
  MultipleFilesHookResult,
  useFileUploadItem,
  useMultipleFilesUpload,
} from '@nhost/react'
import { DialogProps } from '@radix-ui/react-dialog'
import { BanIcon, FileIcon, TrashIcon } from 'lucide-react'
import React, {
  ChangeEvent,
  InputHTMLAttributes,
  ReactNode,
  useEffect,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'

type FileUploadItemProps = {
  file: FileItemRef
  onRemove: (id: string) => void
  onUpload: (id: string) => void
}
const FileUploadItem = ({ file, onRemove, onUpload }: FileUploadItemProps) => {
  const { name, progress, cancel, isUploading, isUploaded, id } =
    useFileUploadItem(file)

  useEffect(() => {
    if (isUploaded && id) {
      onUpload(id)
    }
  }, [isUploaded])

  return (
    <>
      <div>
        <FileIcon className="tw-size-4" />
      </div>
      <div className="tw-text-sm tw-break-words tw-overflow-hidden tw-text-ellipsis">
        {name}
      </div>
      <Progress className="tw-w-20 tw-h-2" value={progress} />
      {isUploading || isUploaded ? (
        <Button
          variant="destructive"
          size="sm"
          className="!tw-p-2"
          disabled={isUploaded}
          onClick={cancel}
        >
          <BanIcon className="tw-size-4" />
        </Button>
      ) : (
        <Button
          variant="destructive"
          size="sm"
          className="!tw-p-2"
          disabled={name == null}
          onClick={() => name != null && onRemove(name)}
        >
          <TrashIcon className="tw-size-4" />
        </Button>
      )}
    </>
  )
}

export type MultipleFileUploadDialogProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'type'
> &
  Pick<DialogProps, 'open' | 'onOpenChange'> & {
    children?: ReactNode
    title?: ReactNode
    description?: ReactNode
    onFileUpload: (id: string) => void
    onDone: () => void
    loading?: boolean
    uploadParams?: Parameters<MultipleFilesHookResult['upload']>[0]
  }
export const MultipleFileUploadDialog = ({
  onFileUpload,
  onDone,
  uploadParams = {},
  open,
  onOpenChange,
  children,
  title,
  description,
  loading,
  ...props
}: MultipleFileUploadDialogProps) => {
  const { t } = useTranslation()
  const { toast } = useToast()

  const [selectedFiles, setSelectedFiles] = useState<File[]>([])

  useEffect(() => {
    if (open) return
    setSelectedFiles([])
  }, [open])

  const filesUpload = useMultipleFilesUpload()

  useEffect(() => {
    filesUpload.clear()
    filesUpload.add({ files: selectedFiles })
  }, [selectedFiles])

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files == null) return
    if (event.target.files.length <= 0) return
    const toAdd = [...event.target.files]
    setSelectedFiles((files) => [...files, ...toAdd])
  }

  const handleFileRemove = (name: string) => {
    setSelectedFiles((files) => files.filter((file) => file.name !== name))
  }

  const handleFileUpload = (id: string) => {
    onFileUpload(id)
  }

  const handleSubmit = async () => {
    if (!filesUpload.files.length) return
    filesUpload.upload(uploadParams)
  }

  useEffect(() => {
    if (filesUpload.isUploaded) {
      toast({
        variant: 'success',
        description: t('FileUpload.toast.success'),
      })
      onDone()
    }
  }, [filesUpload.isUploaded, filesUpload.files])

  useEffect(() => {
    if (filesUpload.isError) {
      toast({
        variant: 'destructive',
        description: t('FileUpload.toast.error'),
      })
    }
  }, [filesUpload.isError])

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      {children}
      {loading && <Loading active />}
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            {title || t('MultipleFileUploadDialog.title')}
          </DialogTitle>
          {!!description && (
            <DialogDescription>{description}</DialogDescription>
          )}
        </DialogHeader>
        <div
          className={cn(
            'tw-grid tw-grid-cols-[max-content_1fr_max-content_max-content] tw-overflow-hidden tw-gap-x-4 tw-gap-y-1 tw-items-center'
          )}
        >
          {filesUpload.files.map((file) => (
            <FileUploadItem
              key={file.id}
              file={file}
              onRemove={handleFileRemove}
              onUpload={handleFileUpload}
            />
          ))}
        </div>
        <div className={cn('tw-flex tw-flex-col tw-gap-4')}>
          <Label htmlFor="file-upload" className={cn(buttonVariants())}>
            {t('MultipleFileUploadDialog.add')}
          </Label>
          <Input
            /** key change between open or close state to force input reset */
            key={open ? 'open' : 'close'}
            id="file-upload"
            type="file"
            multiple={true}
            className="tw-hidden"
            onChange={handleFileChange}
            {...props}
          />
          <div className="tw-flex tw-justify-end tw-gap-2">
            <Button variant="outline" onClick={() => onOpenChange?.(false)}>
              {t('common.cancel')}
            </Button>
            <Button
              onClick={handleSubmit}
              disabled={filesUpload.files.length <= 0}
            >
              {t('common.send')}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}
