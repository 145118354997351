import { passwordRegex } from '@almaris/shared/schemas'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

export const usePasswordSchema = () => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'Forms.errors',
  })

  const schema = useMemo(
    () =>
      yup.string().matches(passwordRegex, {
        message: t('password'),
      }),
    [t]
  )

  return { schema }
}

export type PasswordFormValues = yup.InferType<
  ReturnType<typeof usePasswordSchema>['schema']
>
