import ApplicationEntry from '@/application/components/sheet/ApplicationEntry'
import { Badge } from '@/components/ui/badge'
import {
  Application_Status_Enum,
  BuyerMemberFolderFragment,
  useGetBuyerMemberFoldersByApplicationIdQuery,
  WebappApplicationFragment,
} from '@gql'
import { format } from 'date-fns'
import { fr } from 'date-fns/locale'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ApplicationMediasPreview } from './ApplicationMediasPreview'
import { ApolloError } from '@apollo/client'
import { Loading } from '@/common/atoms/Loading'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { BuyerMemberFolders } from '../BuyerMemberFolders'

type AdminContentProps = {
  application: WebappApplicationFragment
  mandateFeeAmount?: number
  transactionalFee?: number
}

const AdminContent = ({
  application,
  mandateFeeAmount = 0,
  transactionalFee = 0,
}: AdminContentProps) => {
  const { t } = useTranslation()

  const { data, loading } = useGetBuyerMemberFoldersByApplicationIdQuery({
    variables: { applicationId: application.id },
    onError: (e: ApolloError) => console.log(e.message),
  })
  const buyerMemberFolders = data?.buyer_member_folder ?? []
  const phone = useMemo(() => {
    const getBmfPhone = (bmf: BuyerMemberFolderFragment) =>
      bmf.mobilePhone ?? bmf.homePhone ?? bmf.workPhone
    const bmfWithPhone = buyerMemberFolders.find(getBmfPhone)
    if (!bmfWithPhone) return ''
    return getBmfPhone(bmfWithPhone)
  }, [buyerMemberFolders])

  const isSuccess = useMemo(
    () => application.status === Application_Status_Enum.Accepted,
    [application]
  )

  const isDefault = useMemo(
    () => application.status === Application_Status_Enum.Submitted,
    [application]
  )

  const isArchived = useMemo(() => application.archived, [application])

  //Le pourcentage de la quote-part acquisition représente le pourcentage de l'emprunt par rapport au montant total de l'acquisition
  // const cotePartAcqui = useMemo(() => {
  //   if (!application) return 0
  //   const { offerAmount, loan } = application
  //   if (!offerAmount || !loan) return 0
  //   return (loan / offerAmount) * 100
  // }, [application])

  const netSeller = useMemo(() => {
    const offerAmount = application.offerAmount ?? 0
    return (
      offerAmount - (offerAmount * transactionalFee) / 100 - mandateFeeAmount
    )
  }, [application.offerAmount])

  const contributionPercent = useMemo(() => {
    if (!application) return 0
    const { offerAmount, contribution } = application
    if (!offerAmount || !contribution) return 0
    return (contribution / offerAmount) * 100
  }, [application])

  //TODO: in the future only show the first document and add a button to see the others ?
  const documents = useMemo(() => {
    if (!application.application_medias) return t('ApplicationSheet.notDefined')

    return (
      <div className="tw-flex tw-flex-wrap tw-gap-2">
        {application.application_medias?.map((applicationMedia) => (
          <ApplicationMediasPreview
            key={applicationMedia.id}
            applicationMedia={applicationMedia}
          />
        ))}
      </div>
    )
  }, [application])

  if (loading) return <Loading center active />
  if (!application) return null
  return (
    <Tabs defaultValue="application" className="tw-w-full">
      <TabsList className="tw-h-auto tw-max-h-[100px] tw-flex-wrap tw-overflow-auto tw-gap-2 tw-justify-stretch">
        <TabsTrigger value="application">
          {t('ApplicationSheet.application')}
        </TabsTrigger>
        <TabsTrigger value="buyerMemberFolders">
          {t('ApplicationSheet.buyerMemberFolders')}
        </TabsTrigger>
      </TabsList>
      <TabsContent value="application">
        <div className="tw-flex tw-flex-col tw-gap-4">
          {isArchived && (
            <Badge variant="destructive" className="tw-mb-2 tw-w-fit">
              {t('ApplicationsTableColumns.archived')}
            </Badge>
          )}
          <ApplicationEntry
            title={t('ApplicationSheet.candidate')}
            value={application.buyer_member.name}
          />
          <ApplicationEntry
            title={t('ApplicationsTableColumns.status')}
            value={
              <Badge
                variant={
                  isDefault ? 'default' : isSuccess ? 'success' : 'secondary'
                }
                className="tw-ml-2"
              >
                {t(`common.applicationStatus.${application.status}`)}
              </Badge>
            }
          />
          <ApplicationEntry
            title={t('ApplicationsTableColumns.visitDate')}
            value={
              // TODO MAKE IT WORK
              application.buyer_member.visit_slots?.[0]?.startTime
                ? format(
                    new Date(application.buyer_member.visit_slots[0].startTime),
                    'dd MMMM yyyy HH:mm',
                    { locale: fr }
                  )
                : t('ApplicationSheet.notDefined')
            }
          />
          {/* TODO add a submittedDate in DB for Application and use it here */}
          <ApplicationEntry
            title={t('ApplicationSheet.offerDate')}
            value={
              application.submitDate
                ? format(
                    new Date(application.submitDate),
                    'dd MMMM yyyy HH:mm',
                    {
                      locale: fr,
                    }
                  )
                : t('ApplicationSheet.notDefined')
            }
          />
          <ApplicationEntry
            title={t('ApplicationSheet.contribution')}
            value={contributionPercent.toFixed(2) + '%'}
          />
          <ApplicationEntry
            title={t('ApplicationSheet.loanAmount')}
            value={(application.loan ?? 0).toFixed(2) + '€'}
          />
          {/* <ApplicationEntry
            title={t('ApplicationSheet.cotePartAcqui')}
            value={cotePartAcqui.toFixed(2) + '%'}
          /> */}
          <ApplicationEntry
            title={t('ApplicationSheet.totAmount')}
            value={(application.offerAmount ?? 0).toFixed(2) + '€'}
          />
          <ApplicationEntry
            title={t('ApplicationSheet.netSeller')}
            value={netSeller.toFixed(2) + '€'}
          />
          {/* Email acquéreur  */}
          <ApplicationEntry
            title={t('ApplicationSheet.email')}
            value={application.buyer_member.email ?? ''}
          />
          {/* Téléphone acquéreur  */}
          <ApplicationEntry title={t('ApplicationSheet.phone')} value={phone} />
          {/* Information du notaire  */}
          <ApplicationEntry
            title={t('ApplicationSheet.notary')}
            value={[
              application.notary.firstName,
              application.notary.lastName,
              application.notary.city,
              application.notary.email,
              application.notary.phone,
            ].join(' ')}
          />
          <ApplicationEntry
            title={t('ApplicationSheet.documents')}
            value={documents}
          />
          <ApplicationEntry
            title={t('ApplicationSheet.comment')}
            value={application.comment ?? t('ApplicationSheet.notDefined')}
          />
        </div>
      </TabsContent>
      <TabsContent value="buyerMemberFolders">
        <BuyerMemberFolders buyerMemberFolders={buyerMemberFolders} />
      </TabsContent>
    </Tabs>
  )
}

export default AdminContent
