import React, { useState, useEffect } from 'react'
import { Button } from '@/components/ui/button'
import { toast } from '@/components/ui/use-toast'
import { useTranslation } from 'react-i18next'
import { EstateMediaFragment } from '@gql'
import { FileUploadDialog, OnUploadProps } from '@/file/FileUploadDialog'
import { Loading } from '@/common/atoms/Loading'
import { useCreateEstateMedia } from '@/estateMedia/hooks/useCreateEstateMedia'

export type MissingCensoredEstateMediaProps = {
  estateMedia: EstateMediaFragment
}

const showToasts = true

export const MissingCensoredEstateMedia = ({
  estateMedia,
}: MissingCensoredEstateMediaProps) => {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const {
    createEstateMedia,
    data: updateEstateMediaMutationData,
    loading: updateEstateMediaMutationLoading,
    error: updateEstateMediaMutationError,
  } = useCreateEstateMedia()

  const handleAddClick = () => {
    setIsOpen(true)
  }

  const handleFileUpload = async ({ id }: OnUploadProps) => {
    setIsOpen(false)
    await createEstateMedia({
      variables: {
        values: {
          fileId: id,
          estateId: estateMedia.estateId,
          typeId: estateMedia.typeId,
          name: estateMedia.name,
          originalVersionId: estateMedia.id,
          isCensored: true,
        },
      },
    })
  }

  useEffect(() => {
    if (showToasts && updateEstateMediaMutationError) {
      toast({
        variant: 'destructive',
        description: t('MissingCensoredEstateMedia.toast.error'),
      })
    }
  }, [updateEstateMediaMutationError])

  useEffect(() => {
    if (showToasts && updateEstateMediaMutationData) {
      toast({
        variant: 'success',
        description: t('MissingCensoredEstateMedia.toast.success'),
      })
    }
  }, [updateEstateMediaMutationData])

  const loading = updateEstateMediaMutationLoading
  if (loading) return <Loading active />
  return (
    <div className="tw-flex tw-flex-row tw-gap-4 tw-items-center">
      <div className="tw-w-full tw-bg-gray-100 tw-flex tw-flex-row tw-gap-4 tw-items-center tw-justify-between tw-rounded-lg tw-p-4">
        <div>{t('MissingCensoredEstateMedia.addFile')}</div>
        <Button variant="outline" onClick={handleAddClick}>
          {t('common.add')}
        </Button>
      </div>
      <FileUploadDialog
        onOpenChange={setIsOpen}
        description={t('MissingCensoredEstateMedia.fileUpload.description')}
        onUpload={handleFileUpload}
        canSetCustomName={false}
        loading={loading}
        open={isOpen}
      />
    </div>
  )
}
