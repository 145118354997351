import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Loading } from '@/common/atoms/Loading'
import TextError from '@/common/atoms/TextError'
import { Title } from '@/common/atoms/Title'
import { EstatesTable } from '../components/table/EstatesTable'
import { EstateAddFormModal } from '../components/Estate/EstateAddFormModal'
import { EstateArchiveDialog } from '../components/EstateArchiveDialog/EstateArchiveDialog'
import { EstateArchiveDialogProvider } from '../context/EstateArchiveDialogContext'
import { useEstateWithApplicationsAndVisitSpans } from '../hooks/useEstateWithApplicationsAndVisitSpans'

export const AgencyEstatesPage = () => {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)

  const [includeArchived, setIncludeArchived] = useState(false)

  const {
    estates: allEstates,
    loading,
    error,
  } = useEstateWithApplicationsAndVisitSpans()

  const estates = useMemo(() => {
    return includeArchived
      ? allEstates
      : allEstates?.filter((estate) => !estate.archived)
  }, [allEstates, includeArchived])

  return (
    <>
      <Title>{t('AgencyEstatesPage.heading')}</Title>
      {loading && <Loading center active />}
      {error && <TextError error={error} />}
      <div className="tw-p-8">
        <div className="tw-flex tw-justify-between tw-items-baseline">
          <h1 className="tw-text-[40px] tw-font-semibold">
            {t('AgencyEstatesPage.heading')}
          </h1>
          <EstateAddFormModal isOpen={isOpen} setIsOpen={setIsOpen} />
        </div>
        <EstateArchiveDialogProvider>
          {estates && (
            <EstatesTable
              estates={estates}
              setIncludeArchived={setIncludeArchived}
            />
          )}
          <EstateArchiveDialog />
        </EstateArchiveDialogProvider>
      </div>
    </>
  )
}
