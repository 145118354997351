import { NhostReactClientConstructorParams } from '@nhost/react'

export const isLocal = ['localhost', '127.0.0.1'].includes(location.hostname)
export const isStaging = location.hostname === 'app-stg.almaris.fr'

// TODO use env variables, find a way to use env var with nhost+turbo+vitejs
const localConfig = {
  // Webapp url
  url: 'http://localhost:3000',
  // Website to redirect unauthentified users to
  websiteUrl: 'http://localhost:3001',
  // Nhost
  nhost: {
    subdomain: 'local',
    region: undefined,
  } as NhostReactClientConstructorParams,
  backendUrl: 'http://localhost:8888',
}

const stagingConfig = {
  url: 'https://app-stg.almaris.fr',
  websiteUrl: 'https://stg.almaris.fr',
  nhost: {
    subdomain: 'hccdrnhgosysdcfborng',
    region: 'eu-central-1',
  } as NhostReactClientConstructorParams,
  backendUrl: 'https://api-stg.almaris.fr',
}

const prodConfig = {
  url: 'https://app.almaris.fr',
  websiteUrl: 'https://www.almaris.fr',
  nhost: {
    subdomain: 'hccdrnhgosysdcfborng', // TODO get prod subdomain
    region: 'eu-central-1',
  } as NhostReactClientConstructorParams,
  backendUrl: 'https://api.almaris.fr',
}

export default {
  ...(isLocal ? localConfig : isStaging ? stagingConfig : prodConfig),

  // Files
  memberPicture: {
    maxSize: 300, // in px
  },

  crisp: {
    websiteId: '90a19cb2-91af-4666-b134-ebf700a755c5', // TODO configure with new id
  },

  sentry: {
    dsn: 'https://c6253058614d1a0948be4f6ee3bec677@o4508252321021952.ingest.de.sentry.io/4508291900571728',
    environment: isLocal ? 'development' : isStaging ? 'staging' : 'production',
  },
}
