import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
export const useEstateSettingOffersSchema = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'Forms.errors' })

  const schema = useMemo(
    () =>
      yup.object().shape({
        endDate: yup.date().required(t('required')),
        endTime: yup.string().required(t('required')),
        buyerMemberIds: yup
          .array(yup.string().min(1, t('required')).required(t('required')))
          .min(1, t('required'))
          .required(t('required')),
      }),
    [t]
  )

  return { schema }
}

export type EstateSettingOffersFormValues = yup.InferType<
  ReturnType<typeof useEstateSettingOffersSchema>['schema']
>
