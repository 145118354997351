import React from 'react'
import TextError from '@/common/atoms/TextError'
import { useParams } from 'react-router-dom'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { useTranslation } from 'react-i18next'
import {
  useCurrentEstate,
  EstateNotFoundError,
} from '../hooks/useCurrentEstate'
import { EstateMediasPhotosTab } from '@/estateMedia/components/EstateMedias/EstateMediasPhotosTab'
import { EstateMediasVirtualVisitTab } from '@/estateMedia/components/EstateMedias/EstateMediasVirtualVisitTab'
import { EstateMediasVideosTab } from '@/estateMedia/components/EstateMedias/EstateMediasVideosTab'
import { Loading } from '@/common/atoms/Loading'

export const EstateMediasPage = () => {
  const { t } = useTranslation()
  const { estateId } = useParams<{ estateId: string }>()

  const { estate, loading, error } = useCurrentEstate()

  return (
    <div className="tw-py-6 tw-px-8">
      <h1 className="tw-text-[40px] tw-font-semibold">
        {t('EstateMediasPage.title')}
      </h1>
      <div className="tw-py-4 md:tw-py-6">
        {loading ? (
          <Loading active />
        ) : error ? (
          <TextError error={error} />
        ) : estate == null ? (
          <TextError error={new EstateNotFoundError(t)} />
        ) : (
          <>
            <Tabs defaultValue="photos" className="tw-w-[650px]">
              <TabsList>
                <TabsTrigger value="photos">
                  {t('EstateMediasPage.photos')}
                </TabsTrigger>
                <TabsTrigger value="virtualVisit">
                  {t('EstateMediasPage.virtualVisit')}
                </TabsTrigger>
                <TabsTrigger value="video">
                  {t('EstateMediasPage.video')}
                </TabsTrigger>
              </TabsList>
              <TabsContent value="photos">
                {estateId && <EstateMediasPhotosTab estate={estate} />}
              </TabsContent>
              <TabsContent value="virtualVisit">
                {estateId && <EstateMediasVirtualVisitTab estate={estate} />}
              </TabsContent>
              <TabsContent value="video">
                {estateId && <EstateMediasVideosTab estate={estate} />}
              </TabsContent>
            </Tabs>
          </>
        )}
      </div>
    </div>
  )
}
