import { Loading } from '@/common/atoms/Loading'
import { useAuthenticationStatus } from '@nhost/react'
import React from 'react'
import PrivateRoute from './PrivateRoute'
import PublicRoute from './PublicRoute'

export default function AppRoute() {
  const { isAuthenticated, isLoading } = useAuthenticationStatus()
  if (isLoading) return <Loading center active />
  if (!isAuthenticated) return <PublicRoute />
  return <PrivateRoute />
}
