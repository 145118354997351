import React from 'react'

import PropTypes from 'prop-types'
import { cn } from '@/lib/utils'

export type SpinnerProps = React.SVGProps<SVGSVGElement> & {
  size?: number
  className?: string
}

export const Spinner = ({
  size = 24,
  className = '',
  ...props
}: SpinnerProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      role="img"
      aria-label="Loading"
      className={cn('tw-animate-spin', className)}
      {...props}
    >
      <path d="M21 12a9 9 0 1 1-6.219-8.56" />
    </svg>
  )
}

Spinner.propTypes = {
  size: PropTypes.number,
  className: PropTypes.string,
}

type LoadingProps = SpinnerProps & {
  active?: boolean
  center?: boolean
}

export const Loading = ({ active, center, ...spinnerProps }: LoadingProps) => {
  return active ? (
    center ? (
      <div className="tw-fixed tw-top-0 tw-bottom-0 tw-left-0 tw-right-0 tw-flex tw-items-center tw-justify-center">
        <Spinner {...spinnerProps} />
      </div>
    ) : (
      <Spinner {...spinnerProps} />
    )
  ) : null
}
