import { useToast } from '@/components/ui/use-toast'
import { ApolloError } from '@apollo/client'
import {
  Estate_Media_Type_Group_Enum,
  EstateFragment,
  useGetEstateMediaQuery,
} from '@gql'
import { useTranslation } from 'react-i18next'

type useEstateMediasProps = {
  estateId: EstateFragment['id']
  typeGroup: Estate_Media_Type_Group_Enum
}

export const useEstateMedias = (variables: useEstateMediasProps) => {
  const { t } = useTranslation()
  const { toast } = useToast()

  const onError = (error: ApolloError) => {
    console.error(error)
    toast({
      title: t('useEstateMedia.error.title'),
      description: t('useEstateMedia.error.description'),
      variant: 'destructive',
    })
  }
  const { data, loading, error } = useGetEstateMediaQuery({
    variables,
    onError,
  })
  const estateMedias = data?.estate_media ?? []
  return { estateMedias, data, loading, error }
}
