import React from 'react'
import { useTranslation } from 'react-i18next'
import { WebappEstateFragment } from '@gql'
import { EstateOtherMediaList } from './EstateOtherMediaList'

export type EstateOtherTabProps = {
  estate: WebappEstateFragment
}
export const EstateOtherTab = ({ estate }: EstateOtherTabProps) => {
  const { t } = useTranslation()

  return (
    <>
      <h2 className="tw-text-2xl tw-font-semibold tw-mb-5">
        {t('EstateOtherTab.title')}
      </h2>
      <div className="tw-flex tw-flex-col tw-gap-4 tw-w-[650px]">
        <EstateOtherMediaList estate={estate} />
      </div>
    </>
  )
}
