import React, { ReactNode, useEffect, useMemo, useState } from 'react'
import { Loading } from '@/common/atoms/Loading'
import TextError from '@/common/atoms/TextError'

export type RequestHandlerProps<T = any> = {
  loading?: boolean
  error?: Error
  data?: any
  control?: (data?: T) => boolean
  controlError?: Error
  children?: ReactNode
}
export const RequestHandler = <T,>({
  loading,
  error: _error,
  data,
  control,
  controlError,
  children,
}: RequestHandlerProps<T>) => {
  const [isLoading, setIsLoading] = useState(loading)
  const [error, setError] = useState<Error | undefined>(_error)
  const [finalError, setFinalError] = useState<Error | undefined>(undefined)
  const isValid = useMemo(() => control?.(data) || true, [control, data])
  const [errorTimeout, setErrorTimeout] = useState<any>()

  useEffect(() => {
    if (isValid) {
      setIsLoading(false)
      setError(undefined)
      if (errorTimeout) {
        clearTimeout(errorTimeout)
        setErrorTimeout(undefined)
      }
      return
    }

    if (loading) {
      setIsLoading(true)
      setError(undefined)
      if (errorTimeout) {
        clearTimeout(errorTimeout)
        setErrorTimeout(undefined)
      }
      return
    }

    if (error) {
      setIsLoading(true)
      console.error(error)

      if (!errorTimeout)
        setErrorTimeout(
          setTimeout(() => {
            setIsLoading(false)
            setFinalError(error)
          }, 1000)
        )
    } else {
      setIsLoading(false)
      if (errorTimeout) {
        clearTimeout(errorTimeout)
        setErrorTimeout(undefined)
      }
    }

    if (!isValid) {
      setIsLoading(false)
      const newError = controlError ?? new Error('Unknown error')
      console.error(newError)

      if (!errorTimeout)
        setErrorTimeout(
          setTimeout(() => {
            setIsLoading(false)
            setFinalError(newError)
          }, 1000)
        )
    }
  }, [loading, error, isValid])

  if (isLoading) return <Loading center active />
  if (finalError) return <TextError error={finalError} />
  return <div>{children}</div>
}
