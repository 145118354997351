import { WebappEstateFragment } from '@gql'
import { useStoreState } from '@store/hooks'
import { TFunction } from 'react-i18next'

export class EstateNotFoundError extends Error {
  constructor(t: TFunction) {
    super(t('common.errors.estate.notFound'))
  }
}

export type UseCurrentEstateReturn = {
  estate?: WebappEstateFragment
  loading: boolean
  error?: Error
}

export const useCurrentEstate = (): UseCurrentEstateReturn => {
  const state = useStoreState((state) => state.estate)

  return {
    estate: state.current,
    loading: state.loading,
    error: state.error,
  }
}
