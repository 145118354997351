import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

export const useEstateIdentityAndAdressSchema = () => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'Forms.errors',
  })

  const schema = useMemo(
    () =>
      yup.object().shape({
        name: yup.string().optional(),
        address: yup.string().optional(),
        city: yup.string().optional(),
        zipCode: yup.string().optional(),
      }),
    [t]
  )

  return { schema }
}

export type EstateIdentityAndAdressSchema = yup.InferType<
  ReturnType<typeof useEstateIdentityAndAdressSchema>['schema']
>
