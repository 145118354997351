import PasswordInput from '@/common/atoms/PasswordInput'
import { Title } from '@/common/atoms/Title'
import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { useToast } from '@/components/ui/use-toast'
import { yupResolver } from '@hookform/resolvers/yup'
import { useSignInEmailPassword } from '@nhost/react'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  Link as ReachLink,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import { LoginFormValues, useLoginSchema } from '../hooks/useLoginSchema'

interface Props {
  defaultEmail?: string
}

const LoginForm = ({ defaultEmail }: Props) => {
  const { t } = useTranslation()
  const { toast } = useToast()
  const [searchParams] = useSearchParams()
  const redirectTo = searchParams.get('redirectTo')
  const navigate = useNavigate()

  const { schema } = useLoginSchema()

  const { signInEmailPassword, isLoading, error } = useSignInEmailPassword()

  const onSubmit = async (values: LoginFormValues) => {
    try {
      const { needsEmailVerification, isSuccess } = await signInEmailPassword(
        values.email,
        values['current-password']
      )
      if (isSuccess) {
        toast({
          description: t('LoginForm.toast.successDescription'),
          title: t('LoginForm.toast.successTitle'),
        })
        if (redirectTo) navigate(redirectTo)
      }
      if (needsEmailVerification) {
        toast({
          variant: 'destructive',
          title: t('LoginForm.toast.error'),
          description: t('LoginForm.toast.emailVerificationNeeded'),
        })
      }
    } catch (error: any) {
      toast({
        variant: 'destructive',
        description: error.message,
      })
    }
  }

  useEffect(() => {
    if (error) {
      toast({
        variant: 'destructive',
        description: error.message,
      })
    }
  }, [error])

  const form = useForm<LoginFormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      email: defaultEmail ?? '',
      ['current-password']: '',
    },
  })

  const email = form.watch('email')

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="tw-space-y-5">
        <Title>{t('LoginForm.heading')}</Title>

        <div className="tw-space-y-2 tw-mb-5">
          <h1 className="tw-text-2xl tw-font-bold">{t('LoginForm.heading')}</h1>
          <p className="tw-text-sm">{t('LoginForm.description')}</p>
        </div>

        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('LoginForm.email')}</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="current-password"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('LoginForm.password')}</FormLabel>
              <FormControl>
                <PasswordInput
                  id="current-password"
                  required
                  autoComplete="current-password"
                  {...field}
                />
              </FormControl>

              <FormMessage />
            </FormItem>
          )}
        />
        <ReachLink
          to={`/forgot-password${email ? `?email=${email}` : ''}`}
          className="tw-block tw-text-sm tw-text-gray-500"
        >
          {t('LoginForm.resetPassword')}
        </ReachLink>
        <Button variant="green" type="submit" disabled={isLoading}>
          {t('LoginForm.submit')}
        </Button>
      </form>
    </Form>
  )
}

export { LoginForm }
