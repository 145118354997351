import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Loading } from '@/common/atoms/Loading'
import TextError from '@/common/atoms/TextError'
import { Title } from '@/common/atoms/Title'
import { EstateAddFormModal } from '../components/Estate/EstateAddFormModal'
import useCurrentSeller from '@/seller/hooks/useCurrentSeller'
import { EstatesCardList } from '../components/EstateCardList/EstatesCardList'
import { nhost } from 'src/nhost'
import { useEstateWithApplicationsAndVisitSpans } from '../hooks/useEstateWithApplicationsAndVisitSpans'

export const SellerEstatesPage = () => {
  const { t } = useTranslation()
  const seller = useCurrentSeller()
  const [isOpen, setIsOpen] = useState(false)

  const [includeArchived, setIncludeArchived] = useState(false)

  const {
    estates: allEstates,
    loading,
    error,
  } = useEstateWithApplicationsAndVisitSpans()

  const estates = useMemo(() => {
    return includeArchived
      ? allEstates
      : allEstates?.filter((estate) => !estate.archived)
  }, [allEstates, includeArchived])

  const getLogoUrl = useCallback(() => {
    if (!seller?.logo) return
    // TODO maybe replace by nhost.storage.getPresignedUrl
    return nhost.storage.getPublicUrl({
      fileId: seller?.logo,
    })
  }, [seller])

  return (
    <>
      <Title>{t('SellerEstatesPage.heading')}</Title>
      {loading && <Loading center active />}
      {error && <TextError error={error} />}

      <div className="tw-bg-[#EBFFF6] tw-max-h-[200px] tw-w-full tw-flex tw-items-center tw-justify-between tw-p-8 tw-overflow-hidden tw-relative">
        <div>
          <img
            src={getLogoUrl()}
            alt="Logo"
            className="tw-max-h-[100px] tw-object-cover"
          />
          <p className="tw-text-lg tw-font-medium tw-mt-6">
            {t('SellerEstatesPage.description', {
              count: estates?.length,
            })}
          </p>
        </div>
        <EstateAddFormModal isOpen={isOpen} setIsOpen={setIsOpen} />
      </div>
      <EstatesCardList
        estates={estates}
        setIncludeArchived={setIncludeArchived}
      />
    </>
  )
}
