import { useMemo } from 'react'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'
import { EPC_Rank_Enum } from '@almaris/shared/model/estate'

export const useEstateDiagnosticsDataSchema = () => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'Forms.errors',
  })

  const schema = useMemo(
    () =>
      yup.object().shape({
        asbestos: yup.boolean().optional(),
        asbestosDate: yup.date().optional(),
        asbestosValidityDate: yup.date().optional(),
        epc: yup.boolean().optional(),
        epcConsumption: yup.number().typeError(t('number')).optional(),
        epcEmission: yup.number().typeError(t('number')).optional(),
        epcDate: yup.date().optional(),
        epcConsumptionRank: yup
          .mixed<EPC_Rank_Enum>()
          .oneOf(Object.values(EPC_Rank_Enum))
          .optional(),
        epcEmissionRank: yup
          .mixed<EPC_Rank_Enum>()
          .oneOf(Object.values(EPC_Rank_Enum))
          .optional(),
        elec: yup.boolean().optional(),
        elecDate: yup.date().optional(),
        elecValidityDate: yup.date().optional(),
        gas: yup.boolean().optional(),
        gasDate: yup.date().optional(),
        gasValidityDate: yup.date().optional(),
        lead: yup.boolean().optional(),
        leadDate: yup.date().optional(),
        leadValidityDate: yup.date().optional(),
        noise: yup.boolean().optional(),
        noiseDate: yup.date().optional(),
        noiseValidityDate: yup.date().optional(),
        ntr: yup.boolean().optional(),
        ntrDate: yup.date().optional(),
        ntrValidityDate: yup.date().optional(),
      }),
    [t]
  )

  return { schema }
}

export type EstateDiagnosticsDataFormValues = yup.InferType<
  ReturnType<typeof useEstateDiagnosticsDataSchema>['schema']
>
