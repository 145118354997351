import { Loading } from '@/common/atoms/Loading'
import TextError from '@/common/atoms/TextError'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { EstateCoownershipTab } from '../components/EstateCoownershipTab/EstateCoownershipTab'
import { EstateDiagnosticsTab } from '../components/EstateDiagnosticsTab/EstateDiagnosticsTab'
import { EstateEstateTab } from '../components/EstateEstateTab/EstateEstateTab'
import { EstateOtherTab } from '../components/EstateOtherTab/EstateOtherTab'
import {
  EstateNotFoundError,
  useCurrentEstate,
} from '../hooks/useCurrentEstate'

export const EstateDocumentsPage = () => {
  const { t } = useTranslation()

  const { estate, loading, error } = useCurrentEstate()

  return (
    <div className="tw-px-8 tw-py-6">
      <h1 className="tw-text-[40px] tw-font-semibold">
        {t('EstateDocumentsPage.title')}
      </h1>
      <div className="tw-py-4 md:tw-py-6">
        {loading ? (
          <Loading active />
        ) : error ? (
          <TextError error={error} />
        ) : estate == null ? (
          <TextError error={new EstateNotFoundError(t)} />
        ) : (
          <Tabs defaultValue="estate" className="tw-w-[650px]">
            <TabsList>
              <TabsTrigger value="estate">
                {t('EstateDocumentsPage.estate')}
              </TabsTrigger>
              <TabsTrigger value="diagnostics">
                {t('EstateDocumentsPage.diagnostics')}
              </TabsTrigger>
              {estate.coownership?.isCoowned && (
                <TabsTrigger value="coownership">
                  {t('EstateDocumentsPage.coownership')}
                </TabsTrigger>
              )}
              <TabsTrigger value="other">
                {t('EstateDocumentsPage.other')}
              </TabsTrigger>
            </TabsList>
            <TabsContent value="estate">
              <EstateEstateTab estate={estate} />
            </TabsContent>
            <TabsContent value="diagnostics">
              <EstateDiagnosticsTab estate={estate} />
            </TabsContent>
            {estate.coownership?.isCoowned && (
              <TabsContent value="coownership">
                <EstateCoownershipTab estate={estate} />
              </TabsContent>
            )}
            <TabsContent value="other">
              <EstateOtherTab estate={estate} />
            </TabsContent>
          </Tabs>
        )}
      </div>
    </div>
  )
}
