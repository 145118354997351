import { Card, CardContent } from '@/components/ui/card'
import { Separator } from '@/components/ui/separator'
import React from 'react'
import { UserInfoForm } from '../components/UserInfoForm'
import { useResetPassword, useUserData } from '@nhost/react'
import { useParams } from 'react-router-dom'
import { Button } from '@/components/ui/button'
import { useTranslation } from 'react-i18next'
import { MemberInfoForm } from '@/sellerMember/components/MemberInfoForm'
import useMember from '@/sellerMember/hooks/useMember'
import useIsAgencyMember from '@/agencyMember/hooks/useIsAgencyMember'
import { Heading } from '@/components/ui/heading'
import { useToast } from '@/components/ui/use-toast'

const UserInfoPage = () => {
  const { toast } = useToast()
  const { t } = useTranslation()
  const { userId } = useParams<{ userId: string }>()

  const member = useMember(userId)
  const isAgencyMember = useIsAgencyMember()
  const userData = useUserData()
  const email = userData?.email

  const { resetPassword, isLoading } = useResetPassword()

  const handleResetPasswordClick = async () => {
    if (!email) return
    const { isSent, isError, error } = await resetPassword(email, {
      redirectTo: '/change-password',
    })

    if (isError) {
      console.error(error)
      toast({
        title: t('UserInfoPage.passwordReset.errorTitle'),
        description: t('UserInfoPage.passwordReset.errorDescription', {
          error: error?.message,
        }),
        variant: 'destructive',
      })
    }
    if (isSent) {
      toast({
        title: t('UserInfoPage.passwordReset.successTitle'),
        description: t('UserInfoPage.passwordReset.successDescription'),
      })
    }
  }

  return (
    <div className="tw-p-8 tw-flex tw-justify-center">
      <div className="tw-space-y-3">
        <Heading>{t('UserInfoPage.heading')}</Heading>
        <div className="tw-gap-3">
          <Card>
            <CardContent className="tw-mt-6">
              {!isAgencyMember ? (
                <MemberInfoForm member={member} />
              ) : (
                <UserInfoForm user={userData} />
              )}
            </CardContent>
          </Card>
          <Separator className="tw-my-6" />
          <h2 className="tw-font-semibold tw-my-3">
            {t('UserInfoPage.password')}
          </h2>
          <Card>
            <CardContent className="tw-mt-6 tw-space-y-5">
              <p className="tw-text-sm tw-text-gray-500">
                {t('UserInfoPage.changePassword')}
              </p>
              <Button
                disabled={!email || isLoading}
                onClick={handleResetPasswordClick}
              >
                {t('UserInfoPage.button.reset')}
              </Button>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  )
}

export { UserInfoPage }
