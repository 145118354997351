import React from 'react'
import { LoginForm } from '../components/LoginForm'
import Logo from 'src/images/logo.svg'

const AuthPage = () => {
  return (
    <div className="tw-grid tw-grid-flow-col tw-grid-cols-2 tw-h-full">
      <div className="tw-flex tw-items-center tw-justify-center tw-py-12 tw-h-[100vh]">
        <div className="tw-mx-auto tw-w-[350px] tw-space-y-6">
          <div className="tw-mb-[70px]">
            <Logo width="150" />
          </div>
          <LoginForm />
        </div>
      </div>
      <div className="tw-bg-white tw-lg:block tw-dark:bg-gray-800 tw-h-[100vh] tw-p-6">
        <img
          src="https://images.unsplash.com/photo-1520608421741-68228b76b6df?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          alt="Cityscape"
          className="tw-h-full tw-w-full tw-object-cover tw-rounded-2xl"
        />
      </div>
    </div>
  )
}

export { AuthPage }
