import React, { useEffect } from 'react'
import { Form, FormField } from '@/components/ui/form'
import { Estate_Type_Enum, WebappEstateFragment } from '@gql'
import { FieldErrors, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { EstateTypeSelect } from './EstateTypeSelect'

const schema = yup.object().shape({
  type: yup.mixed<Estate_Type_Enum>().oneOf(Object.values(Estate_Type_Enum)),
})

const resolver = yupResolver(schema)

export type FormValues = yup.InferType<typeof schema>

export type EstateTypeFormProps = {
  estate: WebappEstateFragment
  onSubmit: (values: Partial<WebappEstateFragment>) => void
}
export const EstateTypeForm = ({ estate, onSubmit }: EstateTypeFormProps) => {
  const defaultValues = {
    type: estate.type ?? Estate_Type_Enum.House,
  }

  const form = useForm<FormValues>({
    resolver,
    defaultValues,
  })

  const onInvalid = (errors: FieldErrors<FormValues>) => {
    console.log({ errors })
  }

  useEffect(() => {
    const { unsubscribe } = form.watch((value, { type }) => {
      if (type !== 'change') return
      form.handleSubmit(({ type }) => {
        onSubmit({ type })
      }, onInvalid)()
    })
    return () => unsubscribe()
  }, [form.watch])

  return (
    <Form {...form}>
      <FormField
        control={form.control}
        name="type"
        render={({ field }) => (
          <EstateTypeSelect {...field} className="tw-mb-4" />
        )}
      />
    </Form>
  )
}
