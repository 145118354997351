'use client'

import * as Sentry from '@sentry/react'
import { useEffect } from 'react'

import { useUserData } from '@nhost/react'

export function SentrySetUser() {
  const user = useUserData()

  useEffect(() => {
    if (user == null) {
      Sentry.setUser(null)
      return
    }

    const sentryUser: Sentry.User = {
      ...user,
      id: user.id,
      username: user.displayName,
      email: user.email,
    }
    Sentry.setUser(sentryUser)
  }, [user])

  return null
}
