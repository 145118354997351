import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

export const useEstateSettingVisitSpanSchema = () => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'Forms.errors',
  })

  const schema = useMemo(
    () =>
      yup.object().shape({
        visitSpans: yup
          .array()
          .of(
            yup.object().shape({
              visitId: yup.string().optional(),
              date: yup.date().required(t('required')),
              span: yup
                .array()
                .of(
                  yup
                    .object()
                    .shape({
                      startTime: yup.date().required(t('required')),
                      endTime: yup.date().required(t('required')),
                    })
                    .required(t('required'))
                )
                .min(1, t('min_array', { count: 1 }))
                .required(t('required')),
            })
          )
          .required(t('required')),
      }),
    [t]
  )

  return { schema }
}

export type EstateSettingVisitSpanFormValues = yup.InferType<
  ReturnType<typeof useEstateSettingVisitSpanSchema>['schema']
>
