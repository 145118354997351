import { Application_Status_Enum, WebappEstateFragment } from '@gql'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { EstateOfferAcceptedUpload } from './EstateOfferAcceptedUpload'

type EstateConfirmDialogContentProps = {
  estate?: WebappEstateFragment
}

// TODO fix this since this should only be text
const EstateConfirmDialogContent = ({
  estate,
}: EstateConfirmDialogContentProps) => {
  const { t } = useTranslation()

  if (!estate) return null

  const isOfferAccepted = useMemo(() => {
    return estate.applications.some(
      (application) => application.status === Application_Status_Enum.Accepted
    )
  }, [estate.applications])

  return (
    <>
      {!estate.isPublished && isOfferAccepted ? (
        <EstateOfferAcceptedUpload estate={estate} />
      ) : (
        <div>
          <p>{t('EstateConfirmDialogContent.ongoing.description.title')}</p>
          <p>{t('EstateConfirmDialogContent.ongoing.description.content')}</p>
          <p>{t('EstateConfirmDialogContent.ongoing.description.confirm')}</p>
        </div>
      )}
    </>
  )
}

export default EstateConfirmDialogContent
