import { InputProps } from '@/components/ui/input'
import React, { ChangeEvent, ForwardedRef, forwardRef, useMemo } from 'react'
import { AutoComplete } from './autocomplete'
import {
  AddressProperties,
  ApiAddressQueryTypeEnum,
  useApiAddress,
} from './useApiAddress'

export type Address = {
  address?: string
  zipCode?: string
  city?: string
}

export enum AddressComboboxModeEnum {
  ADDRESS = 'address',
  ZIP_CODE = 'zipCode',
  CITY = 'city',
}

export type AddressComboboxProps = Omit<InputProps, 'value'> & {
  value?: string
  mode?: AddressComboboxModeEnum
  onSelectItem?: (value: Address) => void
}

const AddressComboboxInner = (
  {
    name,
    value,
    mode = AddressComboboxModeEnum.ADDRESS,
    disabled,
    onBlur,
    onChange,
    onSelectItem,
    ...props
  }: AddressComboboxProps,
  ref: ForwardedRef<HTMLInputElement>
) => {
  const { getResults, data } = useApiAddress({
    type:
      mode !== AddressComboboxModeEnum.ADDRESS
        ? ApiAddressQueryTypeEnum.MUNICIPALITY
        : ApiAddressQueryTypeEnum.HOUSE_NUMBER,
  })

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    getResults(event.target.value)
    onChange?.(event)
  }

  const handleSelectItem = ({
    value: itemValue,
  }: {
    value: AddressProperties
  }) => {
    onSelectItem?.({
      address: itemValue.name,
      zipCode: itemValue.postcode,
      city: itemValue.city,
    })
  }

  const items = useMemo(() => {
    function getLabel(item: AddressProperties) {
      switch (mode) {
        case AddressComboboxModeEnum.ADDRESS:
          return item.label
        case AddressComboboxModeEnum.ZIP_CODE:
          return `${item.postcode} ${item.city}`
        case AddressComboboxModeEnum.CITY:
          return `${item.postcode} ${item.city}`
      }
    }

    return data.map((item) => ({
      id: item.id,
      value: item,
      label: getLabel(item),
    }))
  }, [data])

  return (
    <AutoComplete
      ref={ref}
      disabled={disabled}
      name={name}
      value={value}
      items={items}
      onInput={handleInputChange}
      onSelectItem={handleSelectItem}
      onBlur={onBlur}
      {...props}
    />
  )
}

export const AddressCombobox = forwardRef(AddressComboboxInner)
