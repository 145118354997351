import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

export const useEstateSellerDataSchema = () => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'Forms.errors',
  })

  const schema = useMemo(
    () =>
      yup.object().shape({
        sellerStructures: yup.array(
          yup.object({
            sellerId: yup.string().required(),
            ownershipShare: yup
              .number()
              .typeError(t('number'))
              .required(t('required'))
              .min(0, t('min_number', { min: 0 }))
              .max(100, t('max_number', { max: 100 })),
          })
        ),
        singleRepresentativeForMultipleSellers: yup.boolean().optional(),
        mandatedSellerWithPowerDelegation: yup
          .string()
          .when('singleRepresentativeForMultipleSellers', {
            is: true,
            then: (schema) => schema.required(t('required')),
            otherwise: (schema) => schema.optional(),
          }),
      }),
    [t]
  )

  return { schema }
}

export type EstateSellerDataFormValues = yup.InferType<
  ReturnType<typeof useEstateSellerDataSchema>['schema']
>
