import { useEstateStats } from '@/estate/hooks/useEstateStats'
import { WebappEstateFragment } from '@gql'
import { CalendarCheckIcon, CoinsIcon, FileTextIcon } from 'lucide-react'
import React from 'react'
import { useTranslation } from 'react-i18next'

type ApplicationsStatsBlockProps = {
  estate: WebappEstateFragment
}
export const ApplicationsStatsBlock = ({
  estate,
}: ApplicationsStatsBlockProps) => {
  const { t } = useTranslation()
  const {
    nbPlannedVisits,
    nbDoneVisits,
    nbInProgressApplications,
    nbReceivedApplications,
  } = useEstateStats(estate)

  return (
    <div className="tw-p-8 tw-flex tw-gap-6 tw-items-center tw-justify-between">
      <div className="tw-w-full tw-flex tw-gap-2 tw-bg-green-100 tw-rounded-lg tw-p-4 tw-items-center">
        <CoinsIcon />
        <div>
          <p className="tw-text-2xl tw-font-medium">{estate.firstPrice} €</p>
          <p>{t('ApplicationsStatsBlock.firstPrice')}</p>
        </div>
      </div>

      <div className="tw-w-full tw-flex tw-gap-2 tw-bg-green-100 tw-rounded-lg tw-p-4 tw-items-center">
        <CalendarCheckIcon />
        <div>
          <p>
            <span className="tw-text-2xl tw-font-medium">{nbDoneVisits}</span>/
            {nbPlannedVisits + nbDoneVisits}
          </p>
          <p>{t('ApplicationsStatsBlock.done', { count: nbDoneVisits })}</p>
        </div>
      </div>

      <div className="tw-w-full tw-flex tw-gap-2 tw-bg-green-100 tw-rounded-lg tw-p-4 tw-items-center">
        <FileTextIcon />
        <div>
          <p>
            <span className="tw-text-2xl tw-font-medium">
              {nbReceivedApplications}
            </span>
            /{nbInProgressApplications + nbReceivedApplications}
          </p>
          <p>
            {t('ApplicationsStatsBlock.received', {
              count: nbReceivedApplications,
            })}
          </p>
        </div>
      </div>
    </div>
  )
}
