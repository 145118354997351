import { usePasswordSchema } from '@/common/hooks/schemas/usePasswordSchema'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

export const useResetPasswordSchema = () => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'Forms.errors',
  })
  const { schema: passwordSchema } = usePasswordSchema()

  const schema = useMemo(
    () =>
      yup.object().shape({
        ['new-password']: passwordSchema.required(),
        ['confirm-password']: passwordSchema
          .oneOf([yup.ref('new-password'), undefined], t('passwordMatch'))
          .required(t('required')),
      }),
    [t]
  )

  return { schema }
}

export type ResetPasswordFormValues = yup.InferType<
  ReturnType<typeof useResetPasswordSchema>['schema']
>
