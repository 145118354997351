import { Loading } from '@/common/atoms/Loading'
import TextError from '@/common/atoms/TextError'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { EstateEditForm } from '../components/Estate/EstateEditForm'
import { EstateEstimatePriceCard } from '../components/Estate/EstateEstimatePriceCard'
import {
  EstateNotFoundError,
  useCurrentEstate,
} from '../hooks/useCurrentEstate'

export const EstatePage = () => {
  const { t } = useTranslation()

  const { loading, error, estate } = useCurrentEstate()

  return (
    <div className="tw-px-8 tw-py-6">
      <h1 className="tw-text-[40px] tw-font-semibold">
        {t('EstatePage.title')}
      </h1>
      <div className="tw-w-full tw-grid md:tw-grid-cols-[1fr_40%] tw-gap-6 tw-py-4 md:tw-py-6">
        {loading ? (
          <Loading active />
        ) : error ? (
          <TextError error={error} />
        ) : estate == null ? (
          <TextError error={new EstateNotFoundError(t)} />
        ) : (
          <>
            <EstateEditForm estate={estate} />
            <EstateEstimatePriceCard estate={estate} />
          </>
        )}
      </div>
    </div>
  )
}
