import { Loading } from '@/common/atoms/Loading'
import TextError from '@/common/atoms/TextError'
import { WebappEstateFragment, useApplicationsSubscription } from '@gql'
import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ApplicationsCoreTable } from './ApplicationsCoreTable'
import { ApplicationsTableToolbar } from './ApplicationsTableToolbar'
import { columns } from './ApplicationsTableColumns'
import ApplicationSheet from '@/application/components/sheet/ApplicationSheet'
import useIsAgencyMember from '@/agencyMember/hooks/useIsAgencyMember'

type ApplicationsTableProps = {
  estate: WebappEstateFragment
}

export const ApplicationsTable = ({ estate }: ApplicationsTableProps) => {
  const { t } = useTranslation()
  const [openedApplicationIndex, setOpenedApplicationIndex] =
    useState<number>(-1)

  const openApplication = useCallback((applicationIndex: number) => {
    setOpenedApplicationIndex(applicationIndex)
  }, [])
  const cols = useMemo(() => columns(t, openApplication), [t, openApplication])

  const { data, error, loading } = useApplicationsSubscription({
    variables: { estateId: estate.id },
  })
  const isAdmin = useIsAgencyMember()

  const applications = useMemo(() => {
    return data?.application ?? []
  }, [data])

  const openedApplication = useMemo(() => {
    return applications[openedApplicationIndex]
  }, [openedApplicationIndex, applications])

  const canNext = useMemo(() => {
    return openedApplicationIndex < applications.length - 1
  }, [openedApplicationIndex, applications])

  const canPrev = useMemo(() => {
    return openedApplicationIndex > 0
  }, [openedApplicationIndex])

  const handleNext = useCallback(() => {
    if (canNext) {
      setOpenedApplicationIndex(openedApplicationIndex + 1)
    }
  }, [canNext, openedApplicationIndex])

  const handlePrev = useCallback(() => {
    if (canPrev) {
      setOpenedApplicationIndex(openedApplicationIndex - 1)
    }
  }, [canPrev, openedApplicationIndex])

  const handleOpenChange = useCallback((open: boolean) => {
    open
      ? setOpenedApplicationIndex(openedApplicationIndex)
      : setOpenedApplicationIndex(-1)
  }, [])

  return (
    <>
      <Loading center active={loading} />
      {error && <TextError error={error} />}
      {applications && (
        <ApplicationsCoreTable
          columns={cols}
          data={applications}
          toolbar={(table, grouping, setGrouping) => (
            <ApplicationsTableToolbar
              table={table}
              grouping={grouping}
              setGrouping={setGrouping}
            />
          )}
        />
      )}
      {openedApplication && (
        <ApplicationSheet
          open={openedApplicationIndex !== -1}
          onOpenChange={handleOpenChange}
          onNext={handleNext}
          onPrevious={handlePrev}
          estate={estate}
          isAdmin={isAdmin ?? false}
          application={openedApplication}
        />
      )}
    </>
  )
}
