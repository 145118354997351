import React, { forwardRef, useEffect, useState } from 'react'
import { uploadFile } from '../api/uploads'
import { Input, InputProps } from '@/components/ui/input'
import { nhost } from 'src/nhost'
import { useGetFileQuery } from '@gql'
import { FileTextIcon } from 'lucide-react'
import { Loading } from './Loading'

export type FileUploadInputProps = InputProps & {
  storageName?: string
  accept?: string
  value?: string // value is the file ID stored in the database
  onChange?: (value: string | null) => void
}

export const FileUploadInput = forwardRef<
  HTMLInputElement,
  FileUploadInputProps
>(({ storageName, accept, value, onChange, ...props }, ref) => {
  const [preview, setPreview] = React.useState<string | undefined>(undefined)
  const [_loading, setLoading] = useState(false)
  const { data: getFileData, loading: getFileLoading } = useGetFileQuery({
    variables: {
      id: value!,
    },
    skip: !value,
  })
  const mimeType = getFileData?.file?.mimeType
  const loading = getFileLoading || _loading

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (!file || !storageName) return

    const { id } = await uploadFile({ file, name: storageName })
    onChange?.(id)
  }

  useEffect(() => {
    if (value) {
      setLoading(true)
      // TODO maybe replace by nhost.storage.getPresignedUrl
      const fileUrl = nhost.storage.getPublicUrl({
        fileId: value,
      })
      setPreview(fileUrl)
      setLoading(false)
    }
  }, [value])

  const renderPreview = () => {
    if (!preview) return null
    if (mimeType?.startsWith('image/')) {
      return (
        <img
          src={preview}
          alt="Preview"
          className="tw-h-44 tw-w-44 tw-object-cover tw-rounded-lg tw-mt-4"
        />
      )
    }
    return (
      <a href={preview} target="_blank" rel="noreferrer">
        <FileTextIcon />
      </a>
    )
  }

  return (
    <>
      <Input
        ref={ref}
        type="file"
        onChange={handleChange}
        accept={accept}
        {...props}
      />
      {loading && !preview && <Loading center active />}
      {preview && !loading && renderPreview()}
    </>
  )
})

FileUploadInput.displayName = 'FileUploadInput'
